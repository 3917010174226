var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',[_c('div',{staticClass:"header-bottom covid-banner"},[_c('div',{staticClass:"banner-wrap banner-color"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"}),_c('div',{staticClass:"col-md-6"},[_c('center',[_c('a',{staticClass:"buttonstyle btn filled-btn labtest-btn hvr-grow",attrs:{"href":_vm.started_covid19_female}},[_vm._v(" Get Started ")])]),_c('br')],1),_c('div',{staticClass:"col-md-3"})])])])])]),_c('div',{staticClass:"holder"},[_c('div',{staticClass:"viraltesting-holder common-holder"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-6"},[_vm._m(2),_c('div',{staticClass:"howit-btn text-center",attrs:{"data-aos":"fade-up","data-aos-delay":"0"}},[_c('a',{staticClass:"mobilebutton btn how-btn common-btn hvr-float-shadow",attrs:{"href":_vm.started_covid19_female}},[_vm._v("Order Kit Now")])])]),_vm._m(3)]),_vm._m(4),_c('br'),_c('br'),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"banner-contents",attrs:{"data-aos":"fade-up","data-aos-delay":"0"}},[_c('br'),_c('br'),_c('h1',[_vm._v("Covid-19")]),_c('br'),_c('p',[_vm._v("COVID-19 affects different people in different ways. Most infected people will develop mild to moderate illness and recover without hospitalization.")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"banner-contents",attrs:{"data-aos":"fade-up","data-aos-delay":"0"}},[_c('div',{staticClass:"banner-features"},[_c('ul',{staticClass:"list-unstyled d-flex d-inline-flex m-0"},[_c('li',{staticClass:"surface-duo surfaceduo pixel2 pixel-2"},[_c('span',[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/icons/labtesting-bannericon-1.webp","alt":"icon"}})]),_vm._v(" At-Home Testing ")]),_c('li',{staticClass:"surface-duo pixxel-2 pixel-2 banner-2-icon"},[_c('span',[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/icons/labtesting-bannericon-2.webp","alt":"icon"}})]),_vm._v(" In-Person Testing ")]),_c('li',{staticClass:"pixel-2 surface-duo"},[_c('span',[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/icons/labtesting-bannericon-3.webp","alt":"icon"}})]),_vm._v(" CLIA Certified Labs ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 showDivMb",staticStyle:{"display":"none"}},[_c('div',{staticClass:"viraltesting-img common-img",attrs:{"data-aos-delay":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/others/corona-kit.webp","alt":"post"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"viraltesting-contents common-contents heading",attrs:{"data-aos":"fade-up","data-aos-delay":"0"}},[_c('h1',[_vm._v("SalivaDirect At-Home Collection Kit")]),_c('h2',[_vm._v("Powered by Tempus Labs")]),_c('p',[_vm._v("At Better Doctor we focus on keeping patients safe at home and have made it easy to order a home collection kit sent right to your door. You will typically get results within 12-24 hours of the time our lab receives the sample.")]),_c('ul',[_c('li',[_vm._v("Our Tempus SalivaDirect™ At-Home Collection Kit is FDA EUA Authorized")]),_c('li',[_vm._v("Sample is gathered through saliva collection")]),_c('li',[_vm._v("Results are typically available within 12-24 hours after specimen receipt in our lab")]),_c('li',[_vm._v("Easy-to-use portal for ordering")]),_c('li',[_vm._v("Quickly get results through the Tempus mobile app")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 hideDivMb"},[_c('br'),_c('div',{staticClass:"viraltesting-img common-img",attrs:{"data-aos":"fade-up","data-aos-delay":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/others/corona-kit.webp","alt":"post"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#F8F8F8"}},[_c('div',{staticClass:"col-lg-12",staticStyle:{"justify-content":"center","display":"flex"}},[_c('h2',{staticStyle:{"color":"#474747","padding-top":"20px"}},[_vm._v("How It Works")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pd-top2"},[_c('div',{staticClass:"col-lg-1 col-2"},[_c('span',{staticClass:"dot text-center",staticStyle:{"padding-top":"5px"}},[_vm._v("1")])]),_c('div',{staticClass:"col-lg-5 col-10"},[_c('h2',[_vm._v("Order your test online")]),_c('p',[_vm._v("Complete the online assessment in a matter of minutes. If eligible, your test-kit will be shipped to your home free of charge from our FDA registered manufacturing facility. We'll provide you with an automatic itemized receipt of your order, which can be used for reimbursement purposes from your health insurance provider or health coverage plan.")])]),_c('div',{staticClass:"col-lg-6"},[_c('img',{staticClass:"img-width img-fluid img-pd",attrs:{"src":"img/others/covid-step1.jpg","width":"400px","alt":"post"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pd-top2"},[_c('div',{staticClass:"col-lg-1 col-2"},[_c('span',{staticClass:"dot text-center",staticStyle:{"padding-top":"5px"}},[_vm._v("2")])]),_c('div',{staticClass:"col-lg-5 col-10"},[_c('h2',[_vm._v("Easy at-home collection")]),_c('p',[_vm._v("Self-collect your sample from home following the instructions provided. This is an easy to follow process.")])]),_c('div',{staticClass:"col-lg-6"},[_c('img',{staticClass:"img-width img-fluid img-pd",attrs:{"src":"img/others/covid-step2.png","width":"400px","alt":"post"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pd-top2"},[_c('div',{staticClass:"col-lg-1 col-2"},[_c('span',{staticClass:"dot text-center",staticStyle:{"padding-top":"5px"}},[_vm._v("3")])]),_c('div',{staticClass:"col-lg-5 col-10"},[_c('h2',[_vm._v("Real-time test tracking")]),_c('p',[_vm._v("Our test includes a free express UPS shipping service to deliver the sample to our laboratory for processing. You will receive notifications by SMS and email to keep you informed throughout the entire process.")])]),_c('div',{staticClass:"col-lg-6"},[_c('img',{staticClass:"img-width img-fluid img-pd",attrs:{"src":"img/others/covid-step3.png","width":"400px","alt":"post"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row pd-top2"},[_c('div',{staticClass:"col-lg-1 col-2"},[_c('span',{staticClass:"dot text-center",staticStyle:{"padding-top":"5px"}},[_vm._v("4")])]),_c('div',{staticClass:"col-lg-5 col-10"},[_c('h2',[_vm._v("Results")]),_c('p',[_vm._v("We aim to deliver your results to your secure online account within 24-72 hours after lab receipt. Due to the current global circumstances, minor delays may occur in some cases. We are making every effort to ensure that results are delivered on time. An official lab report will be made available to you, to serve as proof of your result.")])]),_c('div',{staticClass:"col-lg-6"},[_c('img',{staticClass:"img-width img-fluid img-pd",attrs:{"src":"img/others/covid-step4.jpg","width":"400px","alt":"post"}})])])
}]

export { render, staticRenderFns }