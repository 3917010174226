<template>
    <div>
        <header>
            <div class="header-bottom home-banner">
                <div class="banner-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>BETTER TESTING <span>without the hassles</span></h1>
                                    <br>
                                    <p>From Online ordering, to expedited delivery, expect a <b>BETTER</b> experience!</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <div class="banner-features">
                                        <ul class="list-unstyled d-flex d-inline-flex m-0">
                                            <li>
                                            <span>
													<img src="img/icons/labtesting-bannericon-1.webp" class="img-fluid" alt="icon" />
												</span> At-Home Testing
                                            </li>
                                            <li>
                                            <span>
													<img src="img/icons/labtesting-bannericon-2.webp" class="img-fluid" alt="icon" />
												</span> In-Person Testing
                                            </li>
                                            <li>
                                            <span>
													<img src="img/icons/labtesting-bannericon-3.webp" class="img-fluid" alt="icon" />
												</span> CLIA Certified Labs
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <br>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <img src="img/others/viraltesting-img.webp" class="img-fluid" alt="post" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Blood + Hormonal</h1>
                                <p>Understanding your hormonal balances, or blood levels for diseases such as Diabetes can let you better manage your body allowing for a <b>BETTER</b> Tomorrow!</p>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="sub">
                                            <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>STD Testing <i class="fa fa-venus-mars"></i></h2></a>
                                            <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Hormone Testing <i class="fa fa-venus-mars"></i></h2></a>
                                            <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Weight Loss <i class="fa fa-venus-mars"></i></h2></a>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="sub">
                                            <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>General Wellness <i class="fa fa-venus-mars"></i></h2></a>
                                            <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Fertility <i class="fa fa-venus-mars"></i></h2></a>
                                            <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Allergy <i class="fa fa-venus-mars"></i></h2></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="genomictesting-holder common-holder">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 d-block d-sm-block d-md-none">
                                <div class="genomictesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                    <img src="img/others/genomic-testing.webp" class="img-fluid" alt="post" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="genomictesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                    <h1>Genomic Testing</h1>
                                    <p>Understanding your genetics <b>BETTER</b>.<br>Understanding the Best medications for you for <b>BETTER</b> Treatment. Understanding what diseases you're predisposed to get and much more!</p>
                                    <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Cancer Genetic Test <i class="fa fa-venus-mars"></i></h2></a>
                                    <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Pharmaceutical Genetic Test <i class="fa fa-venus-mars"></i></h2></a>
                                    <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Cardiovascular Screening <i class="fa fa-venus-mars"></i></h2></a>
                                </div>
                            </div>
                            <div class="col-md-6 d-none d-sm-none d-md-block">
                                <div class="genomictesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                    <img src="img/others/genomic-testing.webp" class="img-fluid" alt="post" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="blood-holder common-holder">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="blood-img common-img" data-aos="fade-up" data-aos-delay="0">
                                    <img src="img/others/blood-testingimg.webp" class="img-fluid" alt="post" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="blood-contents common-contents heading" data-aos="fade-up" data-aos-delay="0" >
                                    <h1>Custom Testing</h1>
                                    <p>Not Every body is built the same so neither should your test get ready for a <b>BETTER</b> Test!</p>
                                    <a href="https://getbetterdoctor.com/forms/dist/#/select-gender"><h2>Custom Test <i class="fa fa-venus-mars"></i></h2></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="why-holder">
                    <div class="container">
                        <div class="why-heading heading text-center" data-aos="fade-up" data-aos-delay="0">
                            <h1>Why Better-Doctor?</h1>
                            <p>We enable you to have a <b>BETTER</b> Tomorrow by using our innovative online technoloy, and innovative lab partners.</p>
                        </div>
                        <div class="why-contents">
                            <div class="row">
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-1.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Easy to use online Visit</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-2.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>CLIA Certified Labs</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-3.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Control your well being</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-4.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Personalized testing</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-5.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Secure and Confidential</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-6.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>24/7 Access</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="testing-btn text-center" data-aos="fade-up" data-aos-delay="0">
                                <a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class="btn mobilebutton filled-btn testing-btn hvr-wobble-vertical"><i class="fa fa-flask" aria-hidden="true"></i> Get Tested</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
export default {
    name: "Testing",
    components: {Header},
    methods : {

        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>

<style scoped>

</style>