<template>
    <div>
        <example-modal ref="modal"></example-modal>
        <header>
            <div class="header-bottom hairloss-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Men's hair loss</h1>
                                    <br>
                                    <p>BetterDoctor unique formulations are built to target and lower DHT in the scalp to help you maintain your current hair.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                <div class="banner-features">                              
                                    <ul class="list-unstyled d-flex d-inline-flex m-0">
                                        <li class="pixel-2 pixel-3">
                                            <span>
                                                <img src="img/icons/feature-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            U.S. Board Certified <br/> Professionals
                                        </li>
                                        <li class="pixel-2 pixel2 banner-1-icon">
                                            <span class="button-icon-pd">
                                                <img src="img/icons/depression-bannericon-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Secure and <br/> Confidential
                                        </li>
                                        <li class="pixel-2">
                                            <span>
                                                <img src="img/icons/treatment-bannericon-3.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Avoid Expensive <br/> Doctor Visits
                                        </li>
                                    </ul>
                                    <br>
                                </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a :href='started_hairloss' class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br><br><br>
                                <img src="img/others/hairloss-2.png" class="img-fluid" alt="post" />
                            </div>
                        </div>
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/hairloss-2.png" class="img-fluid" alt="post" />
                            </div>
                        </div>                        
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Finasteride Tablets</h1>
                                <p>The active ingredient in Propecia</p>
                                <p>Finasteride is a 5-alpha reductase inhibitor that is FDA-approved for the treatment of male pattern hair loss.</p>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">TRANSPARENT PRICING</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border  price-pd-right">
                                        <p class="price-alignment bbold">30 day supply</p>
                                        <span class="price-alignment bbold">As low as</span>
                                        <h1 class="price-alignment bbold">$0.67</h1>
                                        <span class="price-alignment price-sub bbold">per dose</span>
                                        <p class="price-alignment facts-button"><a @click="showModal">Drug Facts</a></p>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_hairloss' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <section class="testimonial text-center">
                    <div class="container">
                        <div class="heading01 white-heading">
                            Reviews
                        </div>
                        <div id="testimonial4" class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x" data-ride="carousel" data-pause="hover" data-interval="5000" data-duration="2000">
                        
                            <div class="carousel-inner" role="listbox">
                                <div class="carousel-item active">
                                    <div class="testimonial4_slide">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                        <h4>Client 1</h4>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testimonial4_slide">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                        <h4>Client 2</h4>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testimonial4_slide">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                        <h4>Client 3</h4>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#testimonial4" data-slide="prev">
                                <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next" href="#testimonial4" data-slide="next">
                                <span class="carousel-control-next-icon"></span>
                            </a>
                        </div>
                    </div>
                </section>                 -->
                
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Config from '../../config/prod.env';
import exampleModal from "../modal/hairloss.vue"


export default {
    name: "hairloss",
    components: {
        Header,
        "example-modal":exampleModal,
    },
    data() {
        return {
            started_hairloss: Config.STARTED_HAIRLOSS,
        }
    }, 
    methods : {
        Finasteride: function(){
        var pdf = '/pdf/Hairloss/Finasteride.pdf';
        window.open(pdf);
        },
        showModal() {
            let element = this.$refs.modal.$el
            $(element).modal('show')   
        },
        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>

<style scoped>
.bbold {
    font-weight: bold;
}
.facts-button {
    cursor: pointer;
    font-weight: bold;
    color: #0EB8B7;
    text-decoration: underline ;
}
@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 130px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 112px;
    }
}

@media only screen and (min-width: 413px) and (max-width:414px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 104px !important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 766px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 96px;
    }
}

@media only screen and (max-width: 767px) {
    .hideDivMb{
        display: none !important;
    }
    .showDivMb {
        display: block !important;
    }
}

@media only screen and (max-width: 1199px) {
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
    .price-alignment {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .col03 {
        width: 80%;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

.heading01 {
    text-align: center;
    color: #454343;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin-bottom: 70px;
    text-transform: uppercase;
    z-index: 999;
}
.white-heading{
    color: #ffffff;
}
.heading01:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 40px;
    width: 180px;
    border-radius: 4px;
    transform: translateX(-50%);
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
}
.white-heading:after {
    background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
    background-repeat: no-repeat;
    background-position: center;
}
.heading01 span {
    font-size: 18px;
    display: block;
    font-weight: 500;
}
.white-heading span {
    color: #ffffff;
}
/*-----Testimonial-------*/

.testimonial:after {
    position: absolute;
    top: -0 !important;
    left: 0;
    content: " ";
    background-color: black;
    background-size: 100% 100px;
    width: 100%;
    height: 100px;
    float: left;
    z-index: 99;
}
.testimonial {
    min-height: 375px;
    position: relative;
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center;
    background-size: cover;
}
#testimonial4 .carousel-inner:hover{
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
#testimonial4 .carousel-inner:active{
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
#testimonial4 .carousel-inner .item{
    overflow: hidden;
}
.testimonial4_indicators .carousel-indicators{
    left: 0;
    margin: 0;
    width: 100%;
    font-size: 0;
    height: 20px;
    bottom: 15px;
    padding: 0 5px;
    cursor: e-resize;
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    text-align: center;
    white-space: nowrap;
}
.testimonial4_indicators .carousel-indicators li{
    padding: 0;
    width: 14px;
    height: 14px;
    border: none;
    text-indent: 0;
    margin: 2px 3px;
    cursor: pointer;
    display: inline-block;
    background: #ffffff;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators .active{
    padding: 0;
    width: 14px;
    height: 14px;
    border: none;
    margin: 2px 3px;
    background-color: #9dd3af;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar{
    height: 3px;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb{
    background: #eeeeee;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.testimonial4_control_button .carousel-control{
    top: 175px;
    opacity: 1;
    width: 40px;
    bottom: auto;
    height: 40px;
    font-size: 10px;
    cursor: pointer;
    font-weight: 700;
    overflow: hidden;
    line-height: 38px;
    text-shadow: none;
    text-align: center;
    position: absolute;
    background: transparent;
    border: 2px solid #ffffff;
    text-transform: uppercase;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.6s cubic-bezier(0.3,1,0,1);
    transition: all 0.6s cubic-bezier(0.3,1,0,1);
}
.testimonial4_control_button .carousel-control.left{
    left: 7%;
    top: 50%;
    right: auto;
}
.testimonial4_control_button .carousel-control.right{
    right: 7%;
    top: 50%;
    left: auto;
}
.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover{
    color: #000;
    background: #fff;
    border: 2px solid #fff;
}
.testimonial4_header{
    top: 0;
    left: 0;
    bottom: 0;
    width: 550px;
    display: block;
    margin: 30px auto;
    text-align: center;
    position: relative;
}
.testimonial4_header h4{
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.testimonial4_slide{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 70%;
    margin: auto;
    padding: 20px;
    position: relative;
    text-align: center;
}
.testimonial4_slide img {
    top: 0;
    left: 0;
    right: 0;
    width: 136px;
    height: 136px;
    margin: auto;
    display: block;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    position: relative;
    border-radius: 50%;
    box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}
.testimonial4_slide p {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.4;
    margin: 40px 0 20px 0;
}
.testimonial4_slide h4 {
    color: #ffffff;
    font-size: 22px;
}

.testimonial .carousel {
	padding-bottom:50px;
}
.testimonial .carousel-control-next-icon, .testimonial .carousel-control-prev-icon {
    width: 35px;
    height: 35px;
}
@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 410px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -5px !important;
    } 
    .pixel-3 {
        margin-left: 22px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 319px) and (max-width: 320px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -4px !important;
    } 
    .pixel-3 {
        margin-left: 4px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -8px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -9px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
</style>