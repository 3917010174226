<template>
    <div>
        <header>
            <div class="header-bottom covid-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Covid-19</h1>
                                    <br>
                                    <p>COVID-19 affects different people in different ways. Most infected people will develop mild to moderate illness and recover without hospitalization.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <div class="banner-features">
                                        <ul class="list-unstyled d-flex d-inline-flex m-0">
                                            <li class="surface-duo surfaceduo pixel2 pixel-2">
                                            <span>
													<img src="img/icons/labtesting-bannericon-1.webp" class="img-fluid" alt="icon" />
												</span> At-Home Testing
                                            </li>
                                            <li class="surface-duo pixxel-2 pixel-2 banner-2-icon">
                                            <span>
													<img src="img/icons/labtesting-bannericon-2.webp" class="img-fluid" alt="icon" />
												</span> In-Person Testing
                                            </li>
                                            <li class="pixel-2 surface-duo">
                                            <span>
													<img src="img/icons/labtesting-bannericon-3.webp" class="img-fluid" alt="icon" />
												</span> CLIA Certified Labs
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a :href='started_covid19' class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class=" viraltesting-img common-img"  data-aos-delay="0">
                                <img src="img/others/corona-kit.webp" class="img-fluid" alt="post" />
                            </div>
                        </div>                          
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>SalivaDirect At-Home Collection Kit</h1>
                                <h2>Powered by Tempus Labs</h2>
                                <p>At Better Doctor we focus on keeping patients safe at home and have made it easy to order a home collection kit sent right to your door. You will typically get results within 12-24 hours of the time our lab receives the sample.</p>       
                                <ul>
                                    <li>Our Tempus SalivaDirect™ At-Home Collection Kit is FDA EUA Authorized</li>
                                    <li>Sample is gathered through saliva collection</li>
                                    <li>Results are typically available within 12-24 hours after specimen receipt in our lab</li>
                                    <li>Easy-to-use portal for ordering</li>
                                    <li>Quickly get results through the Tempus mobile app</li>
                                </ul>

                            </div>
                            <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                <a :href='started_covid19' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Order Kit Now</a>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb ">
                            <br>
                            <div class=" viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <img src="img/others/corona-kit.webp" class="img-fluid" alt="post" />
                            </div>
                        </div>                        
                    </div>
                    <div style="background-color: #F8F8F8">
                        <div class="col-lg-12" style="justify-content:center; display:flex">
                            <h2 style="color: #474747; padding-top:20px">How It Works</h2>
                        </div>
                    </div>
                    <br><br>
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">1</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Order your test online</h2>
                            <p>Complete the online assessment in a matter of minutes. If eligible, your test-kit will be shipped to your home free of charge from our FDA registered manufacturing facility. We'll provide you with an automatic itemized receipt of your order, which can be used for reimbursement purposes from your health insurance provider or health coverage plan.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step1.jpg" class="img-width img-fluid img-pd" width="400px" alt="post" />
                        </div>
                    </div>
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">2</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Easy at-home collection</h2>
                            <p>Self-collect your sample from home following the instructions provided. This is an easy to follow process.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step2.png" class="img-width img-fluid img-pd" width="400px"  alt="post" />
                        </div>
                    </div>      
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">3</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Real-time test tracking</h2>
                            <p>Our test includes a free express UPS shipping service to deliver the sample to our laboratory for processing. You will receive notifications by SMS and email to keep you informed throughout the entire process.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step3.png" class="img-width img-fluid img-pd" width="400px" alt="post" />
                        </div>
                    </div>                 
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">4</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Results</h2>
                            <p>We aim to deliver your results to your secure online account within 24-72 hours after lab receipt. Due to the current global circumstances, minor delays may occur in some cases. We are making every effort to ensure that results are delivered on time. An official lab report will be made available to you, to serve as proof of your result.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step4.jpg" class="img-width img-fluid img-pd" width="400px" alt="post" />
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Config from '../../config/prod.env';

export default {
    name: "covid19",
    components: {Header},
    data() {
        return {
            started_covid19: Config.STARTED_COVID19,
        }
    },            
    methods : {

        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}

@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .pd-top2 {
        padding-top: 50px;
    }
    .img-pd {
        margin-left: 50px;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
       height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 900px) {
    .img-width {
        width: 700px !important;
    }
}

@media only screen and (max-width: 600px) {
    .hideDivMb{
        display: none !important;
    }
    .showDivMb {
        display: block !important;
    }
}

@media only screen and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 90px;
    }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
    .col03 {
        width: 80%;
    }
}
.dot {
  height: 50px;
  width: 50px;
  background-color: #0eb8b7;
  border-radius: 50%;
  display: inline-block;
  font-size: 23px;
  color: white;
}

@media only screen and (min-width: 410px) and (max-width: 411px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
}
@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 20px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }           
}
@media only screen and (min-width: 412px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 20px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }       
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }       
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }       
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }       
}
@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 18px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }       
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 20px !important;
    } 
    .pixxel-2 {
        margin-right: 13px !important;
    }
}
@media only screen and (max-width: 280px) {
    .col-2 {
        max-width: 25% !important;
    }
    .col-10 {
        max-width: 73% !important;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .surface-duo {
        margin-right: 17px !important;
    }
    .surfaceduo {
        margin-left: 23px !important;
    } 
    .img-width {
        width: 700px !important;
    }
}
</style>