<template>
    <div class="modal" id="exampleModal8" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="" aria-hidden="true">
      <div class="modal-header">
        <h5 class="modal-title">Drug Fact</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="" role="document">
            <div class="modal-body">
                <div>
                    <h1>Scream Cream</h1>
                    <h2>CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit</h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2><br>
                </div>
                <div class="row">
                    <div class="col-12"  style="border-bottom: 2px solid black; border-top: 2px solid black"> 
                        <div class="col-3">
                            <br>
                            <img src="img/qr/qrcode.png"/>
                        </div>
                        <div class="col-9">
                            <span>
                                Scan This QR Code for Patient Specific Medication Information <br>
                                1. Use your smartphone camera to focus on the QR code <br>
                                2. When the camera recognizes the code, it will display a notification to open the webpage <br>
                                3. When the webpage opens, the medication you received will be available <br>
                                4. Click the button to learn more about the medication <br>
                                5. Watch the short video                        
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <br>
                    <p>
                       Since Scream Cream is custom made for each woman, ingredients in the formula may include all or some of the following:
                    </p>
                    <ul>
                        <li>
                            Theophylline 2.5%
                        </li>
                        <li>
                            Arginine 6%
                        </li>
                        <li>
                            Sildenafil 3%
                        </li>
                    </ul>
                    <p>
                        Before using sildenafil topically:
                    </p>
                    <ul>
                        <li>
                            Read the manufacturer's printed information leaflet from inside the pack. It will give you more information about 
                            the tablets and will provide you with a full list of the side-effects which you may experience from taking them.
                        </li>
                        <li>
                            Take sildenafil exactly as your doctor tells you to.
                        </li>
                        <li>
                            Do not take Nitrates nor Alpha Blockers while taking this medicine.
                        </li>
                        <li>
                            Please contact your pharmacist with any questions.
                        </li>
                    </ul>      
                    <p>
                        Before using theophylline topically:
                    </p>      
                    <ul>
                        <li>
                            Tell you doctor if you are allergic to Arginine or any other drugs, or any of the ingredients in 
                            theophylline preparations. Ask your pharmacist for a list of the ingredients. tell your doctor and pharmacist what prescription 
                            medications you are taking, especially allopurinol (Zyloprim), azithromycin (Zithromax), carbamazepine (Tegretol), cimetidine (Tagamet), ciprofloxacin (Cipro), 
                            clarithromycin (Biaxin), diuretics ('water pills'), erythromycin, lithium (Eskalith, Lithobid), oral contraceptives, phenytoin (Dilantin), prednisone (Deltasone), 
                            propranolol (Inderal), rifampin (Rifadin), tetracycline (Sumycin), and other medications for infections or heart disease.
                        </li>
                        <li>
                            Tell your doctor and pharmacist what prescription, nonprescription medications, vitamins, nutritional supplements, and herbal products you are taking or plan to take. 
                            Be sure to mention any of the following: ephedrine, epinephrine, phenylephrine, phenylpropanolamine, or pseudoephedrine. Many other medications may also interact with theophylline, 
                            so be sure to tell your doctor about all the medications you are taking, even those that do not appear on this list. Your doctor may need to change the doses of your medications 
                            or monitor you carefully for side effects.
                        </li>
                        <li>
                            Tell your doctor if you have or have ever had seizures, ulcers, heart disease, an overactive or underactive thyroid gland, high blood pressure, or 
                            liver disease or if you have a history of alcohol abuse.
                        </li>
                        <li>
                            Tell your doctor if you are pregnant, plan to become pregnant, or are breast-feeding. If you become pregnant while taking theophylline, call your doctor.
                        </li>
                        <li>
                            Tell your doctor if you use tobacco products. Cigarette smoking may decrease the effectiveness of theophylline.
                        </li>
                    </ul>  
                    <p>
                        Before taking ergoloid mesylates:
                    </p>   
                    <ul> 
                        <li>
                            Tell your doctor and pharmacist if you are allergic to ergoloid mesylates, other ergot alkaloids (Cafergot, Ergostat, Bellergal), or any other drugs.
                        </li>
                        <li>
                            Tell your doctor and pharmacist what prescription and nonprescription medications you are taking, including vitamins.
                        </li>
                        <li>
                            Tell your doctor if you have or have ever had a mental illness.
                        </li>
                        <li>
                            Telly our doctor if you are pregnant, plan to become pregnant, or are breast-feeding. If you become pregnant while taking ergoloid mesylates, call your doctor.
                        </li>
                    </ul>  
                    <p>
                        This product is custom-made by expert compounding pharmacists. 
                    </p>
                    <p>
                        If you have questions about side effects, call your doctor. Call your doctor for medical advice about side effects. You may report side effects to the FDA at 
                        1-800-FDA-1088. You may also report side effects at http://www.fda.gov/medwatch.
                    </p> 
                    <p>
                        As with any medication, take caution when taking any other prescription or over-the-counter medicine. This includes herbal supplements.
                    </p>
                    <p>
                        These drugs do not protect against sexually transmitted diseases (such as HIV, hepatitis B, gonorrhea, syphilis).
                    </p>
                    <p>
                        **These statements have not been evaluated by the Food and Drug Administration (FDA). Scream Cream is not meant to diagnose‚ treat or cure any disease or medical condition.
                    </p>
                    <p>
                        Please consult your physician before starting any exercise or nutritional supplement program before using this or any products if you are pregnant or have a serious medical condition.
                    </p>
                    <span style="border-bottom: 2px solid black">Copyright 2021 UpToDate, Inc. All Rights Reserved. (Issue 21.4.3.005)</span>
                    <br><br><br>
                    <h2>
                        CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit
                    </h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "modal9",
}
</script>

<style lang="scss" scoped>
.col-3 {
    max-width: 30%;
}
.col-9 {
    max-width: 70% ;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: silver; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black; 
}
.modal{
    width: 50vw;
    position: fixed;
    left: 25%;
    height: 50vw;
    border-radius: 25px;
    // border-radius: 10px;
    background-color: white;
    padding: 20px 20px;
    z-index: 1200;
    vertical-align: middle !important;
    top: 5px;
        &__wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
    }
}
.modal-dialog {
    max-width: auto;
}
.modal-header {
    background-color: #0EB8B7;
    color: white;
    font-weight: bold;
}
.modal-body {
    width: auto;
    z-index: 1111;
    background-color: white !important;
}
.popup {
    position: absolute;
    left: 50%;
    margin-left: -306px;
    top: 100px;
    width: 612px;
    height: 792px;
    border-style: outset;
    overflow: hidden;
}
.popup2 {
    position:absolute;
    left:50%;
    margin-left:-306px;
    top:802px;
    width:612px;
    height:792px;
    border-style:outset;
    overflow:hidden
}
.modal-backdrop.fade {
    opacity: 0 !important ;
}
.modal-backdrop.show {
    opacity: 0 !important;
}
@media only screen and (min-width: 726px) and (max-width: 900px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1280px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 1300px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 726px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
</style>