<template>
    <div>
        <div class="holder">
            <div class="innerfaq-holder faq-section">
                <div class="container">
                    <div class="innerfaq-heading heading text-center"  data-aos="fade-up" data-aos-delay="0">
                        <h1>Privacy Policy</h1>

                        <p>Last Updated : September 13, 2023</p>
                        <br />
                        This Privacy Policy (“Privacy Policy”) describes the types of information Better-Doctor, LLC and its affiliates (“Company,” “we,” “us,” or “our”) may collect from you or that you may provide when you visit the website located at www.getbetterdoctor.com (the “Site), and our practices for collecting, using, maintaining, protecting, and disclosing that information. This Privacy Policy is incorporated into our Terms of Use. All capitalized terms used in this Privacy Policy that are not defined herein shall have the meanings assigned to them in the Terms of Use.<br />
                        <br />
                        This Privacy Policy also explains the privacy practices for certain features wherein you may consult electronically with a health care provider contracted with or employed by M&D Integrations, Inc.  (“MDI”). These features are referred to herein collectively as the “MDI Features.”<br />
                        <br />
                       This Privacy Policy does not apply to information collected by us offline or through any other means, including on any other website operated by Company or any third party. This Privacy Policy does not apply to the practices of companies that Company does not own or control, or of persons that Company does not employ or manage, including any third-party content contributors bound by contract and any third-party websites to which the Site links.<br />
                        <br />
                      Please read this Privacy Policy carefully. By accessing or using the Site, you are confirming that you accept the practices and policies outlined in this Privacy Policy, and you acknowledge and agree that we will collect, use, and disclose your information as set forth in this Privacy Policy. If any term in this Privacy Policy is unacceptable to you, please do not use the Site or provide any personal information.<br />
                        <br />
                       This Privacy Policy may change from time to time, and you continued use of the Site after we make such changes shall constitute your acceptance of those changes, so please check this page periodically for updates.<br />
                        <br />
                        <strong>Types of Information Collected.</strong>&nbsp;We collect several types of information from and about users of the Site, including information:</p>

                        <ul>
                            <li>By which you may be personally identified, such as name, e-mail address, telephone number, or any other information collected on the Site that is defined as personal data or personally identifiable information under applicable law (&ldquo;personal information&rdquo;).</li>
                            <li>About your internet connection, the equipment you use to access the Site, and usage details.</li>
                            <li>Including your photographic or video image to the extent provided by you to Company (as related to the B2BMD Features, and further subject to the B2BMD Notice of Privacy Practices).</li>
                            <li>About your medical condition, medical history, prescriptions, including protected health information (&ldquo;PHI&rdquo;), or other related information, including the results of any questionnaires, surveys, information requests, interviews, or other documentary, text, video, or audio interactions related to the provision of health services (as related to the B2BMD Features, and further subject to the B2BMD Notice of Privacy Practices.</li>
                        </ul>

                        <p>We collect this information:</p>

                        <ul>
                            <li>Directly from you when you provide it to us</li>
                            <li>Automatically as you navigate through the Site. Information collected automatically may include usage details, IP addresses, and information collected through cookies.</li>
                        </ul>

                        <p><u>Protected Health Information (&ldquo;PHI&rdquo;)</u>. Even though Company is not a &ldquo;covered entity&rdquo; as defined in HIPAA, any health care provider you consult through the Services may be a &ldquo;covered entity&rdquo; and therefore subject to the provisions of HIPAA. If you are using the Services, your acceptance of the Terms of Use and this Privacy Policy incorporates your acceptance and consent to the&nbsp;<a href="b2bmd.html">B2BMD Notice of Privacy Practices</a>&nbsp;included on the Site. This notice describes how the health care provider uses and discloses your PHI. Company has agreed that its collection, use, and disclosure of your PHI on behalf of your health care provider will be done consistent with the B2BMD Notice of Privacy Practices except to the extent you have expressly authorized additional uses and disclosures.&nbsp;<u>Information You Provide to Us</u>. The information we collect on or through the Site may include:</p>

                        <ul>
                            <li>Information that you provide by filling in forms on the Site. This includes information provided when you register to use the Site, when we process or respond to your inquiries related to customer service, requests for a consultation, and when you provide feedback on the Site. We may also ask you for information when you report a problem with the Site.</li>
                            <li>Details of transactions you carry out through the Site and the fulfillment of your inquiries or requests.</li>
                            <li>Records and copies of your correspondence (including e-mail addresses) if you contact us.</li>
                            <li>Your search queries on the Site.</li>
                            <li>If you choose to use our Services, with your consent we may collect health information about you relating to your treatment such as your medical history and allergies to medications.</li>
                        </ul>

                        <p><u>Information We Collect Through Automatic Data Collection Technologies</u>. As you navigate through and interact with the Site, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>

                        <ul>
                            <li>Details of your visits to the Site, including traffic data, location data, logs, date and time of access, and the resources that you access and use on the Site.</li>
                            <li>Information about your computer and internet connection, including your IP address, operating system, host domain, and browser type.</li>
                        </ul>

                        <p>The information we collect automatically helps us improve the Site and deliver a better, more personalized service, including by enabling us to:</p>

                        <ul>
                            <li>Estimate our audience size and usage patterns</li>
                            <li>Speed up your searches</li>
                            <li>Recognize you when you return to the Site</li>
                        </ul>

                        <p>The technologies we may use for this automatic data collection may include:</p>

                        <ul>
                            <li><strong>Cookies</strong>. A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of the Site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to the Site.</li>
                            <li><strong>Web Beacons</strong>. Pages on the Site may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Company, for example, to count users you have visited those pages and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
                        </ul>

                        <p><u>Third Party Use of Cookies</u>. Some content or applications, including advertisements, on the Site are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies to collect information about you when you use the Site. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third parties&rsquo; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.&nbsp;<strong>How We Use Your Information</strong>. We use information that we collect about you or that you provide to us, including any personal information:</p>

                        <ul>
                            <li>To present the Site and its contents to you.</li>
                            <li>To provide the Services and, as applicable, refer you to a physician for a consultation.</li>
                            <li>To fulfill any other purpose for which you provide it.</li>
                            <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                            <li>To notify you about changes to the Site or any products or services we offer or provide through it.</li>
                            <li>In any other way we may describe when you provide the information.</li>
                            <li>For any purpose for which you have given your consent.</li>
                        </ul>

                        <p><strong>Disclosure of Your Information</strong>. We may disclose aggregated, de-identified information about our users, and information that does not identify any individual, without restriction. We may disclose personal information that we collect or you provide as described in this Privacy Policy:</p>

                        <ul>
                            <li>To B2BMD&rsquo;s employed or contracted health care providers.</li>
                            <li>To contractors, service providers, and other third parties we use to support our business or in connection with providing the Services and fulfilling orders for Products.</li>
                            <li>To our third-party service providers that provide services related to hosting the Site, data analysis, IT services and infrastructure, customer service, e-mail delivery, auditing, and similar services</li>
                            <li>To fulfill the purpose for which you provide it.</li>
                            <li>For any other purpose disclosed by us when you provide the information, or with your consent.</li>
                        </ul>

                        <p>We may also disclose your personal information:</p>

                        <ul>
                            <li>To comply with any court order, law, or legal process, including to respond to any government or regulator request.</li>
                            <li>To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes.</li>
                            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Better-Doctor, LLC, our customers, or others.</li>
                        </ul>

                        <p><u>Choices About How We Use and Disclose Your Information</u>. We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>

                        <ul>
                            <li><strong>Tracking Technologies and Advertising</strong>. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of the Site may then be inaccessible or not function properly.</li>
                            <li><strong>Disclosure of Your Information for Third-Party Advertising</strong>. If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by checking the relevant box located on the form on which we collect your data (the registration form). You can always opt-out by logging into the Site and adjusting your user preferences on your account page or by sending us an e-mail with your request to <a href="mailto:support@getbetterdoctor.com"> support@getbetterdoctor.com</a></li>
                        </ul>

                        <p>We do not control third parties&rsquo; collection or use of your information to serve interest-based advertising; however, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;NAI&rdquo;) on the NAI&rsquo;s website:<a href="http://optout.networkadvertising.org"> http://optout.networkadvertising.org.</a><br />
                            <br />
                            <strong>Accessing and Correcting Your Information</strong>. You can review and change your personal information by logging into the Site and visiting your account page.<br />
                            <br />
                            <strong>Data Security</strong>. We implement measures designed to protect the information we collect from accidental loss, misuse, and unauthorized access, alteration, and destruction, including regular malware scanning. Your personal information is contained on secure servers and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the information confidential. Any payment transactions will be encrypted using SSL technology.<br />
                            <br />
                            Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to the Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Site.<br />
                            <br />
                            <strong>Third-Party Websites</strong>. Our Site and Services may contain links to third-party websites. Any access to and use of third-party websites is not governed by this Privacy Policy, and instead is governed by the privacy policies of such websites. We are not responsible for the information practices of any third-party websites.<br />
                            <br />
                            <strong>Children Under the Age of 13</strong>. Our Site is not intended for children under the age of 13. No one under age 13 may provide any personal information to or on the Site. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Site or through any of its features, register on the Site, make any purchases through the Site, or provide any information about yourself to us, including your name, address, telephone number, e-mail address, or any user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information.<br />
                            <br />
                            <strong>Contact Us</strong>. To ask questions or comment about this Privacy Policy or the Site (other than the B2BMD Features), contact us at:<br />
                            <br />
                            Better-Doctor, LLC</p>

                        <p><br />
                            <a href="https://goo.gl/maps/3k8pkxtZJVqkdCE59">2232 Dell Range Blvd. Suite 245-3239 Cheyenne, WY 82009</a></p>

                        <p>
                            <br />
                            Please direct any questions you may have about the B2BMD Features to <a href="tel:+1 (888)-286-8124">+1 (888)-286-8124 </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>