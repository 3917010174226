<template>
    <div class="modal" id="exampleModal5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="" aria-hidden="true">
      <div class="modal-header">
        <h5 class="modal-title">Drug Fact</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="" role="document">
            <div class="modal-body">
                <div>
                    <h1>Patient Education - Acyclovir 200 Mg Capsule</h1>
                    <h2>CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit</h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2><br>
                </div>
                <div class="row">
                    <div class="col-12"  style="border-bottom: 2px solid black; border-top: 2px solid black"> 
                        <div class="col-3">
                            <br>
                            <img src="img/qr/qrcode.png"/>
                        </div>
                        <div class="col-9">
                            <span>
                                Scan This QR Code for Patient Specific Medication Information <br>
                                1. Use your smartphone camera to focus on the QR code <br>
                                2. When the camera recognizes the code, it will display a notification to open the webpage <br>
                                3. When the webpage opens, the medication you received will be available <br>
                                4. Click the button to learn more about the medication <br>
                                5. Watch the short video                        
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <br>
                    <p>
                        CONSUMER INFORMATION USE AND DISCLAIMER This generalized information is a limited summary of diagnosis, treatment, and/or medication information. It is
                        not meant to be comprehensive and should be used as a tool to help the user understand and/or assess potential diagnostic and treatment options. It does NOT include all
                        information about conditions, treatments, medications, side effects, or risks that may apply to a specific patient. It is not intended to be medical advice or a substitute for the
                        medical advice, diagnosis, or treatment of a health care provider based on the health care provider's examination and assessment of a patient's specific and unique
                        circumstances. Patients must speak with a health care provider for complete information about their health, medical questions, and treatment options, including any risks or
                        benefits regarding use of medications. This information does not endorse any treatments or medications as safe, effective, or approved for treating a specific patient.
                        UpToDate, Inc. and its affiliates disclaim any warranty or liability relating to this information or the use thereof. The use of this information is governed by the Terms of
                        Use, available at https://www.wolterskluwer.com/en/solutions/lexicomp/about/eula.
                    </p>
                    <span>
                        Acyclovir Capsules and Tablets <br>
                        PRONUNCIATION (ay SYE kloe veer)
                    </span>
                    <span>
                        BRAND NAME(S): Zovirax
                    </span>
                    <p>
                        WHAT IS THIS DRUG USED FOR? It is used to treat herpes infections.; It is used to treat shingles.; It is used to treat chickenpox.; It may be given to you for other
                        reasons. Talk with the doctor.
                    </p>
                    <p>
                        WHAT DO I NEED TO TELL MY DOCTOR BEFORE I TAKE THIS DRUG? If you are allergic to this drug; any part of this drug; or any other drugs, foods, or substances.
                        Tell your doctor about the allergy and what signs you had.This drug may interact with other drugs or health problems.Tell your doctor and pharmacist about all of your drugs
                        (prescription or OTC, natural products, vitamins) and health problems. You must check to make sure that it is safe for you to take this drug with all of your drugs and health
                        problems. Do not start, stop, or change the dose of any drug without checking with your doctor.
                    </p>
                    <p>
                        WHAT ARE SOME THINGS I NEED TO KNOW OR DO WHILE I TAKE THIS DRUG? For all uses of this drug: Tell all of your health care providers that you take this
                        drug. This includes your doctors, nurses, pharmacists, and dentists. ; Drink lots of noncaffeine liquids every day unless told to drink less liquid by your doctor.; If you are 65
                        or older, use this drug with care. You could have more side effects.; Tell your doctor if you are pregnant, plan on getting pregnant, or are breast-feeding. You will need to talk
                        about the benefits and risks to you and the baby.For herpes infection: This drug is not a cure for herpes infections. Talk with the doctor.; If you have genital herpes, this drug
                        will not stop it from spreading. Do not have any kind of sex when you have sores or other signs of genital herpes. Genital herpes can also be spread if you do not have any
                        signs. Do not have any kind of sex without using a latex or polyurethane condom. Talk with your doctor.
                    </p>
                    <p>
                        WHAT ARE SOME SIDE EFFECTS THAT I NEED TO CALL MY DOCTOR ABOUT RIGHT AWAY? WARNING/CAUTION: Even though it may be rare, some people
                        may have very bad and sometimes deadly side effects when taking a drug. Tell your doctor or get medical help right away if you have any of the following signs or
                        symptoms that may be related to a very bad side effect: Signs of an allergic reaction, like rash; hives; itching; red, swollen, blistered, or peeling skin with or without fever;
                        wheezing; tightness in the chest or throat; trouble breathing, swallowing, or talking; unusual hoarseness; or swelling of the mouth, face, lips, tongue, or throat.; Change in
                        the way you act.; Mood changes.; Feeling confused.; Hallucinations (seeing or hearing things that are not there).; Seizures.; Shakiness.; Very bad and sometimes deadly
                        kidney problems have happened with this drug. Call your doctor right away if you are unable to pass urine or if you have blood in the urine or a change in the amount of
                        urine passed.; Very bad and sometimes deadly blood problems like thrombotic thrombocytopenic purpura/hemolytic uremic syndrome (TTP/HUS) have happened with this
                        drug in some people. Call your doctor right away if you feel very tired or weak or have any bruising or bleeding; dark urine or yellow skin or eyes; pale skin; change in the
                        amount of urine passed; change in eyesight; change in strength on 1 side is greater than the other, trouble speaking or thinking, or change in balance; or fever.
                    </p>
                    <p>
                        WHAT ARE SOME OTHER SIDE EFFECTS OF THIS DRUG? All drugs may cause side effects. However, many people have no side effects or only have minor side
                        effects. Call your doctor or get medical help if any of these side effects or any other side effects bother you or do not go away: Upset stomach or throwing up.; Feeling tired
                        or weak.These are not all of the side effects that may occur. If you have questions about side effects, call your doctor. Call your doctor for medical advice about side
                        effects.You may report side effects to the FDA at 1-800-332-1088. You may also report side effects at https://www.fda.gov/medwatch.
                    </p>
                    <p>
                        HOW IS THIS DRUG BEST TAKEN? Use this drug as ordered by your doctor. Read all information given to you. Follow all instructions closely.; Take with or without
                        food. Take with food if it causes an upset stomach.; If you are taking this drug to treat cold sores, chickenpox, shingles, or genital herpes, start this drug as soon as you can
                        after your signs start. This drug may not help if you start taking it too late.; Keep taking this drug as you have been told by your doctor or other health care provider, even if
                        you feel well.
                    </p>
                    <p>
                        WHAT DO I DO IF I MISS A DOSE? Take a missed dose as soon as you think about it.; If it is close to the time for your next dose, skip the missed dose and go back to
                        your normal time.; Do not take 2 doses at the same time or extra doses.
                    </p>
                    <p>
                        HOW DO I STORE AND/OR THROW OUT THIS DRUG? Store at room temperature protected from light. Store in a dry place. Do not store in a bathroom.; Keep all drugs
                        in a safe place. Keep all drugs out of the reach of children and pets.; Throw away unused or expired drugs. Do not flush down a toilet or pour down a drain unless you are
                        told to do so. Check with your pharmacist if you have questions about the best way to throw out drugs. There may be drug take-back programs in your area.
                    </p>
                    <p>
                        GENERAL DRUG FACTS If your symptoms or health problems do not get better or if they become worse, call your doctor. ; Do not share your drugs with others and do
                        not take anyone else's drugs.; Some drugs may have another patient information leaflet. Check with your pharmacist. If you have any questions about this drug, please talk
                        with your doctor, nurse, pharmacist, or other health care provider.; If you think there has been an overdose, call your poison control center or get medical care right away. Be
                        ready to tell or show what was taken, how much, and when it happened.
                    </p>
                    <span style="border-bottom: 2px solid black">Copyright 2021 UpToDate, Inc. All Rights Reserved. (Issue 21.4.3.005)</span>
                    <br><br><br>
                    <h2>
                        CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit
                    </h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2>
                    <p>
                        Under certain circumstances a prescription drug's effectiveness may be affected by exposire to extremes of heat, cold, or humidty.
                        Where should i store my medicine?
                    </p>
                    <p>
                        Store at controlled room temperature. Store at 20 degrees celsius to 25 degrees celsuis, excursions permitted between 15 degrees celsius and 30 degrees celsius. Brief exposure to
                        temperatures up to 40 degrees celsius may be tolerated provied the mean kinetic temperature does not exceed 25 degrees celsius; however, such exposure should be minimized.
                        The product is to be transferred to proper storage conditions without delay, as instructed by the package insert, ideally within 2 hours of receipt.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "modal6",
}
</script>

<style lang="scss" scoped>
.col-3 {
    max-width: 30%;
}
.col-9 {
    max-width: 70% ;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: silver; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black; 
}
.modal{
    width: 50vw;
    position: fixed;
    left: 25%;
    height: 50vw;
    border-radius: 25px;
    // border-radius: 10px;
    background-color: white;
    padding: 20px 20px;
    z-index: 1200;
    vertical-align: middle !important;
    top: 5px;
        &__wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
    }
}
.modal-dialog {
    max-width: auto;
}
.modal-header {
    background-color: #0EB8B7;
    color: white;
    font-weight: bold;
}
.modal-body {
    width: auto;
    z-index: 1111;
    background-color: white !important;
}
.popup {
    position: absolute;
    left: 50%;
    margin-left: -306px;
    top: 100px;
    width: 612px;
    height: 792px;
    border-style: outset;
    overflow: hidden;
}
.popup2 {
    position:absolute;
    left:50%;
    margin-left:-306px;
    top:802px;
    width:612px;
    height:792px;
    border-style:outset;
    overflow:hidden
}
.modal-backdrop.fade {
    opacity: 0 !important ;
}
.modal-backdrop.show {
    opacity: 0 !important;
}
@media only screen and (min-width: 726px) and (max-width: 900px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1280px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 1300px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 726px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
</style>