<template>
    <div>
        <example-modal3 ref="modal"></example-modal3>
        <example-modal4 ref="modal2"></example-modal4>
        <header>
            <div class="header-bottom ed-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Men's Sexual Problems</h1>
                                    <br>
                                    <p>ED (erectile dysfunction) is when a man is unable to get or maintain an erection, while PE (premature ejaculation) is when a man ejaculates quickly during sexual activity.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                <div class="banner-features">                              
                                    <ul class="list-unstyled d-flex d-inline-flex m-0">
                                        <li class="pixel4 pixel-4 pixel-2 pixel-3">
                                            <span>
                                                <img src="img/icons/feature-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            U.S. Board Certified <br/> Professionals
                                        </li>
                                        <li class="pixel-2 pixel2 pixel-4 banner-1-icon">
                                            <span class="button-icon-pd">
                                                <img src="img/icons/depression-bannericon-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Secure and <br/> Confidential
                                        </li>
                                        <li class="pixel-2">
                                            <span class="pixel-4">
                                                <img src="img/icons/treatment-bannericon-3.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Avoid Expensive <br/> Doctor Visits
                                        </li>
                                    </ul>
                                    <br>
                                </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a :href='started_ed' class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 hideDivMb ">
                            <br><br><br><br>
                            <div class=" viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <img src="img/others/ed-med-1.png" class="img-fluid" width="450px" alt="post" />
                            </div>
                        </div>  
                        <div class="col-md-6 showDivMb" style="display:none">
                            <br><br>
                            <div class=" viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <img src="img/others/ed-med-1.png" class="img-fluid" width="450px" alt="post" />
                            </div>
                        </div>                                                                     
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>E.D. Lozenges</h1>
                                <h3 class="bbold" style="color:#0EB8B7">Intimacy isn't always planned, take control with fast acting lozenges!</h3>
                                <h2>Available in both Sildenafil & Tadalafil</h2>
                                <p> Fast-acting Sildenafil or Tadalafil lozenges promote quick absorption and rapid onset. Lozenges kick in faster than their tablet counterpart, in as little as 15 minutes. Since the lozenges are dissolved under the tongue they start working much faster than a tablet that has to pass through the digestive system. A sublingual dosage form is not as effected by food ingestion as the orally administered tablets.</p>
                                
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">Pricing</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border price-pd-right">
                                   <p class="price-alignment bbold">Sildenafil or Tadalafil Lozenges</p>
                                        <p class="price-alignment bbold">as low as</p>
                                        <h1 class="price-alignment bbold">$6</h1>
                                        <span class="price-alignment price-sub bbold">per dose</span>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_ed' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>                                
                            </div>
                        </div>                       
                    </div>
                </div>
                <div class="container">
                    <div class="row"> 
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/ed-med2.png" class="img-fluid" alt="post" width="600px" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Sildenafil</h1>
                                <p>Active ingredient in Viagra</p>
                                <p>Sildenafil is a PDE5 Inhibitor that is FDA-approved for the treatment of erectile dysfunction (ED).</p>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">Pricing</p>
                                        
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border price-pd-right">
                                        <p class="price-alignment bbold">Sildenafil</p>
                                        <p class="price-alignment bbold">as low as</p>
                                        <h1 class="price-alignment bbold">$4</h1>
                                        <span class="price-alignment price-sub bbold">per dose</span>
                                        <p class="price-alignment facts-button"><a @click="showModal4">Drug Facts</a></p>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_ed' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br><br><br><br>
                                <img src="img/others/ed-med2.png" class="img-fluid" alt="post" width="550px" />
                            </div>
                        </div>                                              
                    </div>
                </div>                  
                <div class="container">
                    <div class="row"> 
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br><br><br><br>
                                <img src="img/others/ed-med3.png" class="img-fluid" alt="post" width="550px"  />
                            </div>
                        </div>        
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/ed-med3.png" class="img-fluid" alt="post" width="550px"  />
                            </div>
                        </div>                                                                  
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Tadalafil</h1>
                                <p>The active ingredient in Cialis</p>
                                <p>Tadalafil is a PDE5 Inhibitor that is FDA-approved for the treatment of erectile dysfunction (ED). Once-daily Tadalafil dosing is also an option.</p>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">Pricing</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border price-pd-right">
                                       <p class="price-alignment bbold">Tadalafil</p>
                                        <p class="price-alignment bbold">as low as</p>
                                        <h1 class="price-alignment bbold">$3</h1>
                                        <span class="price-alignment price-sub bbold">per dose</span>
                                        <p class="price-alignment facts-button"><a @click="showModal5">Drug Facts</a></p>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_ed' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>                                               
                    </div>
                </div>  
                <div class="container">
                    <div class="row">   
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/wipes-1.png" class="img-fluid" alt="post" width="100%" />
                            </div>
                        </div>                                                                     
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Endurance Wipes</h1>
                                <p>Extend your pleasure with easy-to-use endurance wipes. Up to 30% of men experience Premature Ejaculation, but it is treatable. Better-Doctor Endurance Wipes contain a low dose of benzocaine, a topical desensitizer clinically proven to effectively treat premature ejaculation. Each box contain 8 individually-wrapped wipes for one-time use.</p>
                                <ul>
                                    <li>No planning necessary!</li>
                                    <li>Just swipe and let dry minutes before sex.</li>
                                    <li>2" square packets fit in your wallet or pocket.</li>
                                </ul>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">Flat Rate Pricing</p>
                                        <span class="price-alignment bbold price-sub">Regardless of dose and frequency</span>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border price-pd-right">
                                        <p class="price-alignment bbold">30 Day Supply</p>
                                        <h1 class="price-alignment bbold">$30.00</h1>
                                        <p class="price-alignment bbold">90 Day Supply</p>
                                        <h1 class="price-alignment bbold">$60.00</h1>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_ed' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br><br><br><br><br><br><br>
                                <img src="img/others/wipes-1.png" class="img-fluid" alt="post" width="100%" />
                            </div>
                        </div>                                              
                    </div>
                </div>                     
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Config from '../../config/prod.env';
import exampleModal3 from "../modal/Sildenafil.vue"
import exampleModal4 from "../modal/Tadalafil.vue"



export default {
    name: "mensexualproblem",
    components: {
        Header,
        "example-modal3":exampleModal3,
        "example-modal4":exampleModal4,
    
    },
    data() {
        return {
            started_ed: Config.STARTED_ED,
        }
    },    
    methods : {
        Sildenafil: function(){
        // var pdf = '/pdf/ED/Sildenafil.pdf';
        // window.open(pdf);
        window.open("/pdf/ED/Sildenafil.pdf", "width=500,height=500,top=100,left=500")
        },
        showModal4() {
            let element = this.$refs.modal.$el
            $(element).modal('show')   
        },
        Tadalafil: function(){
        var pdf = '/pdf/ED/Tadalafil.pdf';
        window.open(pdf);
        },
        showModal5() {
            let element = this.$refs.modal2.$el
            $(element).modal('show')   
        },        
        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}
.facts-button {
    cursor: pointer;
    font-weight: bold;
    color: #0EB8B7;
    text-decoration: underline ;
}
@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 130px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 112px;
    }
}

@media only screen and (min-width: 413px) and (max-width:414px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 104px !important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 766px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 96px;
    }
}

@media only screen and (max-width: 767px) {
    .hideDivMb{
        display: none !important;
    }
    .showDivMb {
        display: block !important;
    }
}

@media only screen and (max-width: 1199px) {
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
    .price-alignment {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .col03 {
    width: 80%;
  }
}

@media only screen and (max-width: 280px) {
    .pixel-4 {
        margin-right: 5px !important;
    }
    .pixel4 {
        margin-left: -7px !important;
    } 
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -5px !important;
    } 
    .pixel-3 {
        margin-left: 22px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -9px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
    .pd_leftt {
      padding-left: 58px !important;
    }
}
@media only screen and (min-width: 410px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 319px) and (max-width: 320px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -8px !important;
    } 
    .pixel-3 {
        margin-left: 6px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -8px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
</style>