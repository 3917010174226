import { render, staticRenderFns } from "./skin-care.vue?vue&type=template&id=bf0126d8&scoped=true&"
import script from "./skin-care.vue?vue&type=script&lang=js&"
export * from "./skin-care.vue?vue&type=script&lang=js&"
import style0 from "./skin-care.vue?vue&type=style&index=0&id=bf0126d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf0126d8",
  null
  
)

export default component.exports