<template>
    <div>
        <header>
            <div class="header-bottom herpes-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Home Female Fertility Testing</h1>
                                    <br>
                                    <p>Understand your hormonal health with our female fertility testing options.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <div class="banner-features">
                                        <ul class="list-unstyled d-flex d-inline-flex m-0">
                                            <li class="surfaceduo surface-duo pixel2 pixel-2">
                                            <span>
													<img src="img/icons/labtesting-bannericon-1.webp" class="img-fluid" alt="icon" />
												</span> At-Home Testing
                                            </li>
                                            <li class="surface-duo pixxel-2 pixel-2 banner-2-icon">
                                            <span>
													<img src="img/icons/labtesting-bannericon-2.webp" class="img-fluid" alt="icon" />
												</span> In-Person Testing
                                            </li>
                                            <li class="surface-duo pixel-2">
                                            <span>
													<img src="img/icons/labtesting-bannericon-3.webp" class="img-fluid" alt="icon" />
												</span> CLIA Certified Labs
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><span class=" buttonstyle btn filled-btn">Coming Soon</span></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8" style="text-align:center">
                            <br><br>
                            <h1>Know Your Hormones</h1>
                            <p>"1 in 8 couples has trouble getting pregnant or sustaining a pregnancy."</p>
                            <br>    
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div style="background-color: #F8F8F8">
                        <div class="col-lg-12" style="justify-content:center; display:flex">
                            <h1 class="sub-heading " style="color: #474747; padding-top:20px">Fertility Facts</h1>
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8" style="text-align:center">
                            <br>
                            <p>Find out more about the fertility factors.</p>
                        </div>
                        <div class="col-2"></div>
                        <div class="banner-features pd_leftt">
                            <ul class="list-unstyled d-flex d-inline-flex m-0"  style="text-align:center">
                                <li style="color:black !important">
                                <span class="margin-icons">
                                    <img src="img/icons/5.png" class="pd-topp img-fluid" alt="icon" />
                                </span> Stress
                                </li>
                                <li style="color:black !important">
                                <span class="margin-icons">
                                    <img src="img/icons/2.png" class="pd-topp button-icon-pd2 img-fluid" alt="icon" />
                                    </span> Age
                                </li>
                                <li style="color:black !important">
                                <span class="margin-icons">
                                    <img src="img/icons/3.png" class="pd-topp img-fluid" alt="icon" />
                                    </span> Weight
                                </li>
                                <li class="galaxyfold nesthubmax pixel-02 iphone-5" style="color:black !important">
                                <span>
                                    <img src="img/icons/6.png" class="pd-topp img-fluid" alt="icon" />
                                </span> Sexual Health
                                </li>                                
                            </ul>
                        </div>
                    </div>                    
                    <div class="how-margin" style="background-color: #F8F8F8">
                        <div class="col-lg-12" style="justify-content:center; display:flex">
                            <h1 class="sub-heading " style="color: #474747; padding-top:20px">How It Works</h1>
                        </div>
                    </div>
                    <br><br>
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">1</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Discreet Delivery</h2>
                            <p>Your test will be delivered to you in discreet packaging, with next day delivery. Order on-the-go without having to visit a physician's office.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step1.jpg" class="img-width img-fluid img-pd" width="400px" alt="post" />
                        </div>
                    </div>
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">2</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Collect Your Sample</h2>
                            <p>Activate your test and collect your sample in the morning. Return your sample on the same day, using the prepaid shipping label provided.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step2.png" class="img-width img-fluid img-pd" width="400px"  alt="post" />
                        </div>
                    </div>      
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">3</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Receive accurate results</h2>
                            <p>Once your sample arrives in the laboratory, confidential results will be available from your secure online account within 2 to 5 days.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step3.png" class="img-width img-fluid img-pd " width="400px" alt="post" />
                        </div>
                    </div>                 
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">4</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Get Medical Support</h2>
                            <p>Our dedicated team of nurses are available 24/7 and will call you to explain your results.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/covid-step4.jpg" class="img-width img-fluid img-pd" width="400px" alt="post" />
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
export default {
    name: "fertiliytest",
    components: {Header},
    methods : {

        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}
.dot {
  height: 50px;
  width: 50px;
  background-color: #0eb8b7;
  border-radius: 50%;
  display: inline-block;
  font-size: 23px;
  color: white;
}
@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .pd-topp {
        margin-top: -5px;
    }
    .pd_leftt {
        padding-left: 281px;
        margin-top: -20px;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .pd_leftt {
        padding-left: 131px;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

@media screen and (min-width: 395px) and (max-width: 428px) {
    .pd_leftt {
        padding-left: 45px !important;
    }
}

@media only screen and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .sub-head-pd {
        padding-bottom: 28px;
    }
    .pd_leftt {
        padding-left: 33px;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .hideDivMb{
        display: none !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 90px;
    }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .col03 {
    width: 80%;
  }
}

@media only screen and (min-width: 319px) and (max-width: 320px) {
    .iphone-5 {
        margin-left: -5px !important;
    }
    .col-2 {
        max-width: 20% !important;
    }
    .col-10 {
        max-width: 75% !important;
    }
}

@media only screen and (min-width: 359px) and (max-width: 360px) {
    .iphone-5 {
        margin-left: -8px !important;
    }
    .pd_leftt {
        padding-left: 31px !important;
    }   
}

@media only screen and (max-width: 280px) {
    .sub-heading {
        margin-left: 46px;
    }
    .galaxyfold {
        display: none;
    }
    .pd_leftt {
        padding-left: 25px !important;
    }    
    .how-margin {
        margin-top: 25px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }   
    .col-2 {
        max-width: 25% !important;
    }
    .col-10 {
        max-width: 73% !important;
    }
}

@media only screen and (min-width: 374px) and (max-width: 375px) {
    .iphone-5 {
        margin-left: -7px !important;
    }
    .pd_leftt {
        padding-left: 42px !important;
    }    
}

@media only screen and (min-width: 767px) and (max-width: 768px) {
    .pd_leftt {
        padding-left: 150px !important;
    }
    .pixel-02 {
        margin-left: -5px !important;
    }
    .img-width {
        width: 700px !important;
    }
}

@media only screen and (min-width: 1023px) and (max-width: 1024px) {
    .pd_leftt {
        padding-left: 282px;
    }
    .sub-head-pd {
        padding-bottom: 30px;
    }
    .pixel-02 {
        margin-left: -5px !important;
    }
    .pd-topp {
        margin-top: -3px;
    }
    .button-icon-pd2 {
        margin-left: -2px !important;
        margin-top: -5px !important;
	}
}

@media only screen and (min-width: 410px) and (max-width: 411px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
    .pixel-02 {
        margin-left: -7px !important;
    }
    .pd_leftt {
        padding-left: 54px !important;
    }
}

@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 19px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
    .pixxel-2 {
        margin-right: 16px !important;
    }
    .pixel-02 {
        margin-left: -8px !important;
    }    
    .pd_leftt {
        padding-left: 57px !important;
    }    
}
@media only screen and (min-width: 412px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 22px !important;
    } 
    .pixxel-2 {
        margin-right: 14px !important;
    }
    .pixel-02 {
        margin-left: -8px !important;
    }
    .pd_leftt {
        padding-left: 55px !important;
    }        
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 15px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }    
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 22px !important;
    } 
    .pixel-02 {
        margin-left: -8px !important;
    }    
    .pixxel-2 {
        margin-right: 11px !important;
    }        
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 22px !important;
    } 
    .pixel-02 {
        margin-left: -8px !important;
    }
    .pd_leftt {
        padding-left: 49px !important;
    }    
    .pixxel-2 {
        margin-right: 11px !important;
    }           
}
@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 22px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }     
    .pixel-02 {
        margin-left: -8px !important;
    }  
    .pd_leftt {
        padding-left: 48px !important;
    }        
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 22px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }  
    .pd_leftt {
        padding-left: 65px !important;
    }   
          
}
@media only screen and (min-width: 1279px) and (max-width: 1280px) {
    .nesthubmax {
        margin-left: -10px !important;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .surface-duo {
        margin-right: 17px !important;
    }
    .surfaceduo {
        margin-left: 23px !important;
    } 
        .img-width {
        width: 700px !important;
    }
    .pd_leftt {
        padding-left: 120px !important;
    }
    .nesthubmax {
        margin-left: -8px !important;
    }    
}
</style>