<template>
    <div>
        <example-modal2 ref="modal"></example-modal2>
        <header>
            <div class="header-bottom skincare-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Skin care</h1>
                                    <br>
                                    <p>Prescription formulations to help clear acne, reduce scars, fade melasma, improve rosacea, smooth fines lines, or boost brightness.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                <div class="banner-features">                              
                                    <ul class="list-unstyled d-flex d-inline-flexm-0">
                                        <li class="iphone-5 pixel-2 pixel-3">
                                            <span>
                                                <img src="img/icons/feature-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            U.S. Board Certified <br/> Professionals
                                        </li>
                                        <li class="pixel-2 pixel2 iphone-5 banner-1-icon">
                                            <span class="button-icon-pd">
                                                <img src="img/icons/depression-bannericon-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Secure and <br/> Confidential
                                        </li>
                                        <li class="pixel-2 iphone-5">
                                            <span>
                                                <img src="img/icons/treatment-bannericon-3.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Avoid Expensive <br/> Doctor Visits
                                        </li>
                                    </ul>
                                    <br>
                                </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a :href='started_skincare_female' class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 showDivMb" style="display:none">
                            <br>
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <img src="img/others/skincare-01.png" class="img-fluid" width="550px" alt="post" />
                            </div>
                        </div>                          
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Tretinoin</h1>
                                <p>Tretinoin is the active ingredient in Retin-A and arguably the #1 anti-aging prescription choice world-wide. Tretinoin is also FDA-approved to treat acne in adults and children as young as 13 and to lighten hyperpigmentation caused by sun damage or hormone imbalance in adults. Tube size is 20g.</p>
                                <ul> <h3>Treats</h3>
                                    <li>Acne</li>
                                    <li>Reduces Wrinkles</li>
                                    <li>Reduces Spotty Discoloration</li>
                                    <li>Reduces Rough Feeling Skin</li>
                                </ul>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">FLAT RATE</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border price-pd-right">
                                        <p class="price-alignment bbold">Tretinoin</p>
                                        <p class="price-alignment bbold">as low as</p>
                                        <h1 class="price-alignment bbold">$90.00</h1>
                                        <span class="price-alignment price-sub bbold">Per 20 gms</span>
                                        <p class="price-alignment facts-button"><a @click="showModal2">Drug Facts</a></p>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_skincare_female' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb">
                            <br><br><br><br>
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <img src="img/others/skincare-01.png" class="img-fluid" width="550px" alt="post" />
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/Header";
import Config from '../../../config/prod.env';
import exampleModal2 from "../../modal/Tretinoin.vue"

export default {
    name: "female-skincare",
    components: {
        Header,
        "example-modal2":exampleModal2,
    },
    data() {
        return {
            started_skincare_female: Config.STARTED_SKINCARE_FEMALE,
        }
    },     
    methods : {
        Tretinoin: function(){
        var pdf = '/pdf/Skincare/Tretinoin.pdf';
        window.open(pdf);
        },
        showModal2() {
            let element = this.$refs.modal.$el
            $(element).modal('show')   
        },
        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}
.facts-button {
    cursor: pointer;
    font-weight: bold;
    color: #0EB8B7;
    text-decoration: underline ;
}
@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 130px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 112px;
    }
}

@media only screen and (min-width: 413px) and (max-width:414px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 104px !important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 766px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 96px;
    }
}

@media only screen and (max-width: 767px) {
    .hideDivMb{
        display: none !important;
    }
    .showDivMb {
        display: block !important;
    }
}

@media only screen and (max-width: 1199px) {
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
    .price-alignment {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
    .col03 {
        width: 80%;
    }
}   

@media only screen and (min-width: 319px) and (max-width: 320px) {
    .iphone-5 {
        margin-right: 9px !important;
    }
}
@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 18px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 410px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 20px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -5px !important;
    } 
    .pixel-3 {
        margin-left: 22px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -9px !important;
    } 
    .pixel-3 {
        margin-left: 22px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 17px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 24px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
</style>