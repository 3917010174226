<template>
    <div class="modal" id="exampleModal7" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="" aria-hidden="true">
      <div class="modal-header">
        <h5 class="modal-title">Drug Fact</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="" role="document">
            <div class="modal-body">
                <div>
                    <h1>Naltrexone/Bupropion</h1>
                    <h2>CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit</h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2><br>
                </div>
                <div class="row">
                    <div class="col-12"  style="border-bottom: 2px solid black; border-top: 2px solid black"> 
                        <div class="col-3">
                            <br>
                            <img src="img/qr/qrcode.png"/>
                        </div>
                        <div class="col-9">
                            <span>
                                Scan This QR Code for Patient Specific Medication Information <br>
                                1. Use your smartphone camera to focus on the QR code <br>
                                2. When the camera recognizes the code, it will display a notification to open the webpage <br>
                                3. When the webpage opens, the medication you received will be available <br>
                                4. Click the button to learn more about the medication <br>
                                5. Watch the short video                        
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <br>
                    <p>
                        CONSUMER INFORMATION USE AND DISCLAIMER This information should not be used to decide whether or not to take this medicine or any other medicine. Only the healthcare provider
                        has the knowledge and training to decide which medicines are right for a specific patient. This information does not endorse any medicine as safe, effective, or approved for 
                        treating any patient or health condition. This is only a brief summary of general information about this medicine. It does NOT include all information about the possible uses,
                        directions, warnings, precautions, interactions, adverse effects, or risks that may apply to this medicine. This information is not specific medical advice and does not replace information you
                        receive from the healthcare provider. You must talk with the healthcare provider for complete information about the risks and benefits of using this medicine.
                    </p>
                    <span>
                        Naltrexone and Bupropion <br>
                        PRONUNCIATION (nal TREKS one & byoo PROE pee on) <br>
                    </span>
                    <span>
                        BRAND NAME(S): Naltrexone/Bupropion
                    </span>
                    <h3>
                        <br><br>
                        IMPORTANT WARNING:
                    </h3>
                    <p>
                        This medication contains bupropion, the same active ingredient as some antidepressant medications (Wellbutrin, Aplenzin) and a medication used to help people stop smoking (Zyban). 
                        A small number of children, teenagers, and young adults (up to 24 years of age) who took antidepressants ('mood elevators') such as bupropion during clinical studies became suicidal
                        (thinking about harming or killing oneself or planning or trying to do so). Children, teenagers, and young adults who take antidepressants to treat depression or other mental illnesses 
                        may be more likely to become suicidal than children, teenagers, and young adults who do not take antidepressants to treat these conditions. The combination of naltrexone and bupropion is 
                        not approved for use in children under 18 years of age.
                    </p>
                    <p>
                        You should know that your mental health may change in unexpected ways when you take the combination of naltrexone and bupropion even if you are an adult over 24 years of age. You may become suicidal, 
                        especially at the beginning of your treatment and any time that your dose is increased or decreased. You, your family, or your caregiver should call your doctor right away if you experience any
                        of the following symptoms: new or worsening depression; thinking about harming or killing yourself, or planning or trying to do so; extreme worry; agitation; anxiety or panic attacks; difficulty falling 
                        asleep or staying asleep; aggressive, angry, or violent behavior; irritability; acting without thinking; severe restlessness; abnormal thoughts or sensations; feeling that people are against you; 
                        hallucinations (seeing things or hearing voices that do not exist); feeling confused; frenzied abnormal excitement; or any other sudden or unusual changes in behavior. Be sure that your family or caregiver 
                        knows which symptoms may be serious so they can call the doctor if you are unable to seek treatment on your own.
                    </p>
                    <p>
                        Your healthcare provider will want to see you often while you are taking the combination of naltrexone and bupropion, especially at the beginning of your treatment. Be sure to keep all appointments for office visits with your doctor.
                    </p>
                    <p>
                       Your doctor or pharmacist will give you the manufacturer's patient information sheet (Medication Guide) when you begin treatment with the combination of naltrexone and bupropion and each time you refill your prescription. 
                       Read the information carefully and ask your doctor or pharmacist if you have any questions. You can also visit the Food and Drug Administration (FDA) website (http://www.fda.gov/Drugs/DrugSafety/ucm085729.htm) or the manufacturer's website 
                       to obtain the Medication Guide.
                    </p>
                    <p>
                        Talk to your doctor about the risks of taking the combination of naltrexone and bupropion.
                    </p>
                    <h3>
                        Why is this medication prescribed?
                    </h3>
                    <p>
                        The combination of naltrexone and bupropion is used along with a reduced calorie diet and exercise plan to help adults who are obese, or who are overweight and have weight-related medical problems, to lose weight and then to keep from gaining 
                        back that weight. Naltrexone is in a class of medications called opiate antagonists. Bupropion is in a class of medications called antidepressants. These medications work together on two areas of the brain, the hunger center and the reward system, 
                        to reduce appetite and help control cravings.
                    </p>
                    <h3>
                        How should this medicine be used?
                    </h3>
                    <p>
                        The combination of naltrexone and bupropion comes as an extended-release (long-acting) tablet to take by mouth. It is usually taken twice a day. Do not take this medication with a high-fat meal. Take naltrexone and bupropion at around the same times 
                        every day. Follow the directions on your prescription label carefully, and ask your doctor or pharmacist to explain any part you do not understand. Take naltrexone and bupropion exactly as directed. Do not take more or less of it or take it more often than prescribed by your doctor.
                    </p>
                    <p>
                        Swallow the extended-release tablets whole; do not split, chew, or crush them.
                    </p>
                    <p>
                        Your doctor will probably start you on a low dose of the combination of naltrexone and bupropion and gradually increase your dose, not more than once every week, for 4 weeks. After 16 weeks of treatment, your doctor will check to see how much weight you have lost. If you have not lost a 
                        certain amount of weight, your doctor may tell you to stop taking the combination of naltrexone and bupropion as it is unlikely that you will benefit from continuing your treatment.
                    </p>
                    <h3>
                        Other uses for this medicine
                    </h3>
                    <p>
                        This medication may be prescribed for other uses; ask your doctor or pharmacist for more information.
                    </p>
                    <h2>
                        What special precautions should I follow?
                    </h2>
                    <h3>
                        Before taking the combination of naltrexone and bupropion,
                    </h3>
                    <ul>
                        <li>tell your doctor and pharmacist if you are allergic to naltrexone, bupropion, any other medications, or any of the ingredients in naltrexone and bupropion tablets. Ask your pharmacist or 
                            check the Medication Guide for a list of the ingredients.
                        </li>
                        <li>
                            tell your doctor if you are taking a monoamine oxidase (MAO) inhibitor such as isocarboxazid (Marplan), linezolid (Zyvox), methylene blue, phenelzine (Nardil), rasagiline (Azilect), selegiline (Emsam, Zelapar), and tranylcypromine (Parnate) or if you have stopped taking an MAO inhibitor 
                            within the past 14 days. Your doctor will probably tell you not to take the combination of naltrexone and bupropion. If you stop taking the combination of naltrexone and bupropion, your doctor will tell you that you should wait at least 14 days before you start to take an MAO inhibitor.
                        </li>
                        <li>
                            tell your doctor if you are taking any opioid medications or street drugs including heroin, prescription pain medications, tramadol (Ultram, Ultracet), opioid dependence treatments such as buprenorphine (Buprenex, Butrans, Sublocade) or methadone (Dolophine, Methadose), and certain 
                            medications for diarrhea, cough, or cold. Also tell your doctor if you have taken any of these medications in at least the past 7 to 10 days. Ask your doctor if you are not sure if a medication you have taken is an opioid. Your doctor will tell you not to take the combination of naltrexone 
                            and bupropion if you have taken or used opioids in at least the past 7 to 10 days.
                        </li>
                        <li>
                            do not take any opioid medications or use opioid street drugs during your treatment with the combination of naltrexone and bupropion. Naltrexone blocks the effects of opioid medications and opioid street drugs. You may not feel the effects of these substances if you take or use them at low or 
                            normal doses. If you take or use higher doses of opioid medications or drugs during your treatment with the combination of naltrexone and bupropion, it may cause serious injury, coma, or death.
                        </li>
                        <li>
                            you should know that if you took opioid medications before your treatment with the combination of naltrexone and bupropion, you may be more sensitive to the effects of opioid medications when your next dose of the combination of naltrexone and bupropion is due, if you miss a dose of the combination 
                            of naltrexone and bupropion, after you finish your treatment, or if you undergo detoxification. If you use opioids in amounts that you used before your treatment with the combination of naltrexone and bupropion, it can lead to overdose and death. After you finish your treatment, tell any doctor who 
                            may prescribe medications for you that you were previously treated with the combination of naltrexone and bupropion. It is important that you tell your family or caregiver about this increased sensitivity to opioids and the risk of overdose. You or your caregiver should get emergency medical help right 
                            away if you develop any of the following symptoms: trouble breathing, slow shallow breathing, drowsiness, feeling faint, dizziness, or confusion.
                        </li>
                        <li>
                            do not take more than one product containing bupropion at a time, including antidepressants or smoking cessation products. You could receive too much bupropion and experience severe side effects.
                        </li>
                        <li>
                            tell your doctor and pharmacist what other prescription and nonprescription medications, vitamins, nutritional supplements, and herbal products you are taking or plan to take. Be sure to mention any of the following: amantadine (Osmolex ER), amitriptyline, amoxapine, carbamazepine (Carbatrol, Tegretol), 
                            citalopram (Celexa), clopidogrel (Plavix), desipramine (Norpramin), dexamethasone, digoxin (Lanoxin), doxepin (Silenor), efavirenz (Sustiva, in Atripla), escitalopram (Lexapro), flecainide (Tambocor), fluoxetine (Prozac), fluvoxamine (Luvox), haloperidol (Haldol), imipramine (Tofranil), insulin or oral medications 
                            for diabetes, levodopa (in Sinemet, in Stalevo), lopinavir (in Kaletra), methylprednisolone (Medrol), metoprolol (Lopressor, Toprol XL), nortriptyline (Pamelor), paroxetine (Paxil), phenobarbital, phenytoin (Dilantin), prednisone, propafenone (Rythmol SR), protriptyline (Vivactil), risperidone (Risperdal), 
                            ritonavir (Norvir, in Kaletra), sertraline (Zoloft), theophylline (Theo-24, Theochron), thioridazine, ticlopidine, trimipramine (Surmontil), venlafaxine (Effexor), and other weight loss medications. Your doctor may need to change the doses of your medications or monitor you carefully for side effects. Many other 
                            medications may also interact with naltrexone and bupropion, so be sure to tell your doctor about all the medications you are taking, even those that do not appear on this list.
                        </li>
                        <li>
                            tell your doctor if you have or have ever had seizures, anorexia nervosa (an eating disorder), or bulimia (an eating disorder), and if you have high blood pressure that cannot be controlled. Also tell your doctor if you drink large amounts of alcohol but expect to suddenly stop drinking, you take sedatives, hypnotics, 
                            benzodiazepines, or anti-seizure medicines but expect to suddenly stop taking them, or if you are in opioid withdrawal. Your doctor will probably tell you not to take the combination of naltrexone and bupropion.
                        </li>
                        <li>
                            tell your doctor if you or anyone in your family has thought about or attempted suicide or has or have ever had bipolar disorder (mood that changes from depressed to abnormally excited), mania (frenzied, abnormally excited mood), depression, schizophrenia (a mental illness that causes disturbed or unusual thinking, 
                            loss of interest in life, and strong or inappropriate emotions), or other mental illnesses; if you are older than 65 years of age; if you smoke or if you expect to stop smoking; and if you have or have ever had a head injury, heart attack, stroke, tumor or infection of the brain or spine, diabetes, low blood sugar, 
                            low levels of sodium in the blood, or heart, kidney, or liver disease.
                        </li>
                        <li>
                            tell your doctor if you are pregnant, plan to become pregnant, or are breastfeeding. If you become pregnant while taking the combination of naltrexone and bupropion, call your doctor immediately.
                        </li>
                        <li>
                            if you are having surgery, including dental surgery, tell the doctor or dentist that you are taking the combination of naltrexone and bupropion.
                        </li>
                        <li>
                            ask your doctor about the safe use of alcoholic beverages while you are taking the combination of naltrexone and bupropion. Alcohol can make the side effects from naltrexone and bupropion worse.
                        </li>
                        <li>
                            you should know that the combination of naltrexone and bupropion may cause high blood pressure and a faster heart rate. Your doctor may check your blood pressure and heart rate before starting treatment and regularly while you are taking this medication.
                        </li>
                        <li>
                            you should know that the combination of naltrexone and bupropion may cause angle-closure glaucoma (condition where the fluid is suddenly blocked and unable to flow out of the eye causing a quick, severe increase in eye pressure which may lead to a loss of vision). Talk to your doctor about having an eye examination 
                            before you start taking this medication to see if you are at risk for this condition. If you have eye pain, changes in vision, or swelling or redness in or around the eye, call your doctor or get emergency medical treatment right away.
                        </li>
                    </ul>
                    <h3>
                        What special dietary instructions should I follow?
                    </h3>
                    <p>
                        Unless your doctor tells you otherwise, continue your normal diet.
                    </p>
                    <h3>
                        What should I do if I forget a dose?
                    </h3>
                    <p>
                        Skip the missed dose and continue your regular dosing schedule. Do not take a double dose to make up for a missed one.
                    </p>
                    <h3>
                        What side effects can this medication cause?
                    </h3>
                    <h4>
                        The combination of naltrexone and bupropion may cause side effects. Tell your doctor if any of these symptoms are severe or do not go away:
                    </h4>
                    <ul>
                        <li>
                            nausea
                        </li>
                        <li>
                            vomiting
                        </li>  
                        <li>
                            diarrhea
                        </li>
                        <li>
                            constipation
                        </li>
                        <li>
                            dry mouth
                        </li>
                        <li>
                            changes in your sense of taste
                        </li>  
                        <li>
                            headache
                        </li>
                        <li>
                            dizziness
                        </li>  
                        <li>
                            tiredness
                        </li>
                        <li>
                            flushing
                        </li>
                        <li>
                            uncontrollable shaking of a part of the body
                        </li>
                        <li>
                            excessive sweating
                        </li>
                        <li>
                            ringing in the ears
                        </li>                                                                                               
                    </ul>
                    <h4>
                        Some side effects can be serious. If you experience any of these symptoms or those listed in the IMPORTANT WARNING and SPECIAL PRECAUTIONS sections, 
                        stop taking the combination of naltrexone and bupropion and call your doctor immediately or get emergency medical treatment:
                    </h4>
                    <ul>
                        <li>
                            seizures
                        </li>
                        <li>
                            rash or blisters
                        </li>  
                        <li>
                            itching
                        </li>
                        <li>
                            hives
                        </li>
                        <li>
                            swollen glands
                        </li>
                        <li>
                            painful sores in your mouth or around your eyes
                        </li>  
                        <li>
                            shortness of breath
                        </li>
                        <li>
                            swelling of the lips or tongue
                        </li>  
                        <li>
                            chest pain
                        </li>
                        <li>
                            fever
                        </li>
                        <li>
                            stomach pain
                        </li>
                        <li>
                            dark urine
                        </li>
                        <li>
                            yellowing of the skin or eyes
                        </li>        
                        <li>
                            muscle or joint pain
                        </li>                                                                                       
                    </ul>   
                    <p>
                        The combination of naltrexone and bupropion may cause other side effects. Call your doctor if you have any unusual problems while taking this medication.
                    </p>  
                    <p>
                        If you experience a serious side effect, you or your doctor may send a report to the Food and 
                        Drug Administration's (FDA) MedWatch Adverse Event Reporting program online (http://www.fda.gov/Safety/MedWatch) or by phone (1-800-332-1088).    
                    </p>   
                    <h3>
                        What should I know about storage and disposal of this medication?
                    </h3>     
                    <p>
                        Keep this medication in the container it came in, tightly closed, and out of reach of 
                        children. Store it at room temperature and away from excess heat and moisture (not in the bathroom)    
                    </p>      
                    <p>
                        It is important to keep all medication out of sight and reach of children as many containers (such as weekly pill minders and those for eye drops, creams, patches, and inhalers) are not child-resistant and young children can open them easily. To protect young children from poisoning, 
                        always lock safety caps and immediately place the medication in a safe location – one that is up and away and out of their sight and reach. http://www.upandaway.org
                    </p> 
                    <p>
                        Unneeded medications should be disposed of in special ways to ensure that pets, children, and other people cannot consume them. However, you should not flush this medication down the toilet. Instead, the best way to dispose of your medication is through a medicine take-back program. Talk to your pharmacist or contact your local garbage/recycling department to learn about 
                        take-back programs in your community. See the FDA's Safe Disposal of Medicines website (http://goo.gl/c4Rm4p) for more information if you do not have access to a take-back program.
                    </p>
                    <h3>
                        In case of emergency/overdose
                    </h3>
                    <p>
                        In case of overdose, call the poison control helpline at 1-800-222-1222. Information is also available online at https://www.poisonhelp.org/help. If the victim has collapsed, had a seizure, has trouble breathing, or can't be awakened, immediately call emergency services at 911.
                    </p>
                    <h3>
                        Symptoms of overdose may include the following:
                    </h3>
                    <ul>
                        <li>
                            seizure
                        </li>
                        <li>
                            seeing things or hearing voices that do not exist
                        </li>
                        <li>
                            loss of consciousness
                        </li>
                        <li>
                            rapid or pounding heartbeat
                        </li>
                    </ul>
                    <h3>
                        What other information should I know?
                    </h3>
                    <p>
                        Keep all appointments with your doctor and the laboratory.
                        Before having any laboratory test, tell your doctor and the laboratory personnel that you are taking the combination of naltrexone and bupropion.
                        Do not let anyone else take your medication. Ask your pharmacist any questions you have about refilling your prescription.
                    </p>
                    <p>
                        It is important for you to keep a written list of all of the prescription and nonprescription (over-the-counter) medicines you are taking, as 
                        well as any products such as vitamins, minerals, or other dietary supplements. You should bring this list with you each time you visit a doctor or if you are 
                        admitted to a hospital. It is also important information to carry with you in case of emergencies.
                    </p>
                    <span style="border-bottom: 2px solid black">Copyright 2021 UpToDate, Inc. All Rights Reserved. (Issue 21.4.3.005)</span>
                    <br><br><br>
                    <h2>
                        CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit
                    </h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "modal8",
}
</script>

<style lang="scss" scoped>
.col-3 {
    max-width: 30%;
}
.col-9 {
    max-width: 70% ;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: silver; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black; 
}
.modal{
    width: 50vw;
    position: fixed;
    left: 25%;
    height: 50vw;
    border-radius: 25px;
    // border-radius: 10px;
    background-color: white;
    padding: 20px 20px;
    z-index: 1200;
    vertical-align: middle !important;
    top: 5px;
        &__wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
    }
}
.modal-dialog {
    max-width: auto;
}
.modal-header {
    background-color: #0EB8B7;
    color: white;
    font-weight: bold;
}
.modal-body {
    width: auto;
    z-index: 1111;
    background-color: white !important;
}
.popup {
    position: absolute;
    left: 50%;
    margin-left: -306px;
    top: 100px;
    width: 612px;
    height: 792px;
    border-style: outset;
    overflow: hidden;
}
.popup2 {
    position:absolute;
    left:50%;
    margin-left:-306px;
    top:802px;
    width:612px;
    height:792px;
    border-style:outset;
    overflow:hidden
}
.modal-backdrop.fade {
    opacity: 0 !important ;
}
.modal-backdrop.show {
    opacity: 0 !important;
}
@media only screen and (min-width: 726px) and (max-width: 900px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1280px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 1300px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 726px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
</style>