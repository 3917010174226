<template>
    <div>
        <example-modal5 ref="modal"></example-modal5>
        <example-modal6 ref="modal2"></example-modal6>
        <header>
            <div class="header-bottom herpes-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Herpes</h1>
                                    <br>
                                    <p>Genital herpes is a common sexually transmitted infection caused by the herpes simplex virus (HSV).</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                <div class="banner-features">                              
                                    <ul class="list-unstyled d-flex d-inline-flex m-0">
                                        <li class="pixel-2 pixel-3">
                                            <span>
                                                <img src="img/icons/feature-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            U.S. Board Certified <br/> Professionals
                                        </li>
                                        <li class="pixel-2 pixel2 banner-1-icon">
                                            <span class="button-icon-pd">
                                                <img src="img/icons/depression-bannericon-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Secure and <br/> Confidential
                                        </li>
                                        <li class="pixel-2">
                                            <span>
                                                <img src="img/icons/treatment-bannericon-3.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Avoid Expensive <br/> Doctor Visits
                                        </li>
                                    </ul>
                                    <br>
                                </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a :href='started_herpes' class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/herpes-1.png" class="img-fluid" alt="post" />
                            </div>
                        </div>                          
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Valacyclovir-Generic Valtrex</h1>
                                <p>Generic Valtrex is an anti-viral medication prescribed to treat and suppress HSV-1 and HSV-2 viruses. Although there is no cure for herpes, valacyclovir can help reduce the frequency and severity of breakouts. HSV-1 causes oral herpes and HSV-2 causes genital herpes. Each form of the virus can spread from one area to the other.</p>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">TRANSPARENT PRICING</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border  price-pd-right">
                                        <p class="price-alignment bbold">Valacyclovir</p>
                                        <p class="price-alignment bbold">as low as</p>
                                        <h1 class="price-alignment bbold">$1.00</h1>
                                        <span class="price-alignment price-sub bbold">Per Pill</span>
                                        <p class="price-alignment facts-button"><a @click="showModal6">Drug Facts</a></p>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_herpes' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br><br>
                                <img src="img/others/herpes-1.png" class="img-fluid" alt="post" />
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br><br><br>
                                <img src="img/others/herpes-2.png" class="img-fluid" alt="post" />
                            </div>
                        </div>   
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/herpes-2.png" class="img-fluid" alt="post" />
                            </div>
                        </div>                                                  
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Acyclovir-Generic Zovirax</h1>
                                <p>Zovirax-Acyclovir is an anti-viral medication prescribed for the treatment and suppression of oral herpes (HSV 1) and genital herpes (HSV 2). Acyclovir can reduce the severity of breakouts and speed healing, especially when taken at the immediate onset of a breakout.</p>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">TRANSPARENT PRICING</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border price-pd-right">
                                        <p class="price-alignment bbold">Acyclovir</p>
                                        <p class="price-alignment bbold">as low as</p>
                                        <h1 class="price-alignment bbold">$1.00</h1>
                                        <span class="price-alignment price-sub bbold">Per Dose</span>
                                        <p class="price-alignment facts-button"><a @click="showModal5">Drug Facts</a></p>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_herpes' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                                                             
                            </div>
                        </div>                     
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Config from '../../config/prod.env';
import exampleModal5 from "../modal/Acyclovir.vue";
import exampleModal6 from "../modal/Valacyclovir.vue";


export default {
    name: "herpes",
    components: {
        Header,
        "example-modal5":exampleModal5,
        "example-modal6":exampleModal6,

        },
    data() {
        return {
            started_herpes: Config.STARTED_HERPES,
        }
    },     
    methods : {
        Acyclovir: function(){
        var pdf = '/pdf/Herpes/Acyclovir.pdf';
        window.open(pdf);
        },
        showModal5() {
            let element = this.$refs.modal.$el
            $(element).modal('show')   
        },   
        Valacyclovir: function(){
        var pdf = '/pdf/Herpes/Valacyclovir.pdf';
        window.open(pdf);
        },
        showModal6() {
            let element = this.$refs.modal2.$el
            $(element).modal('show')   
        },  
        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}
.facts-button {
    cursor: pointer;
    font-weight: bold;
    color: #0EB8B7;
    text-decoration: underline ;
}
@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 130px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 112px;
    }
}

@media only screen and (min-width: 413px) and (max-width:414px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 104px !important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 766px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 96px;
    }
}

@media only screen and (max-width: 767px) {
    .hideDivMb{
        display: none !important;
    }
    .showDivMb {
        display: block !important;
    }
}

@media only screen and (max-width: 1199px) {
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
    .price-alignment {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .col03 {
        width: 80%;
    }
}
@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 410px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -5px !important;
    } 
    .pixel-3 {
        margin-left: 22px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 319px) and (max-width: 320px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -6px !important;
    } 
    .pixel-3 {
        margin-left: 4px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -8px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -9px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
</style>