<template>
    <div>
        <header>
            <div class="header-bottom home-banner">
                <div class="banner-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>BETTER TREATMENTS <span>Delivered</span></h1>
                                    <br>
                                    <p>From Online Visits, Ongoing Care and The Best Prices, have a <b>BETTER</b> experience.</p>
                                </div>
                                <br>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <div class="banner-features">
                                        <ul class="list-unstyled d-flex d-inline-flex m-0">
                                            <li>
												<span>
													<img src="img/icons/feature-1.webp" class="img-fluid" alt="icon" />
												</span>
                                                Licensed Medical Professionals
                                            </li>
                                            <li>
												<span>
													<img src="img/icons/labtesting-bannericon-2.webp" class="img-fluid" alt="icon" />
												</span>
                                                Personalized Prescriptions
                                            </li>
                                            <li>
												<span>
													<img src="img/icons/treatment-bannericon-3.webp" class="img-fluid" alt="icon" />
												</span>
                                                Licensed Pharmacy
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <br>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="sexualhealth-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="sexualhealth-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <img src="img/others/sexualhealth-img.webp" class="img-fluid" alt="post" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="sexualhealth-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Sexual health</h1>
                                <p>A healthy life includes a healthy sex life. Start the process of having a <b>BETTER</b> sex life today.</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="sub">
                                            <a href="https://getbetterdoctor.com/forms/dist/#/ed/1"><h2>Scream Cream <i class="fa fa-venus" style="color:pink"></i></h2></a>
                                            <a href="https://getbetterdoctor.com/forms/dist/#/ed/1"><h2>Birth Control <i class="fa fa-venus" style="color:pink"></i></h2></a>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="sub">
                                            <a href="https://getbetterdoctor.com/forms/dist/#/ed/1"><h2>Erectile Dysfunction <i class="fa fa-mars"  style="color:blue"></i></h2></a>
                                            <a href="https://getbetterdoctor.com/forms/dist/#/herpes/1"><h2>Cold Sores <i class="fa fa-venus-mars"></i></h2></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="hairnskin-holder common-holder">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 d-block d-sm-block d-md-none">
                                <div class="hairnskin-img common-img" data-aos="fade-up" data-aos-delay="0">
                                    <img src="img/others/hairnskin-img.webp" class="img-fluid" alt="post" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="hairnskin-contents common-contents heading" data-aos="fade-up" data-aos-delay="0" >
                                    <h1>Hair + Skin</h1>
                                    <p>Better-Doctor offers  <b>BETTER</b> hair loss and skincare treatments. From receding hairlines, to thinning hair, to acne, to fine lines, or wrinkles we offer a <b>BETTER</b> solution.</p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="sub">
                                                <a href="https://getbetterdoctor.com/forms/dist/#/skin/1"><h2>Skin Care <i class="fa fa-venus-mars"></i></h2></a>
                                                <a href="https://getbetterdoctor.com/forms/dist/#/hair/1"><h2>Hair Loss <i class="fa fa-venus-mars"></i></h2></a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="sub">
                                                <a href="https://getbetterdoctor.com/forms/dist/#/skin/1"><h2>Acne<i class="fa fa-venus-mars"></i></h2></a>
                                                <a href="https://getbetterdoctor.com/forms/dist/#/skin/1"><h2>Eczema <i class="fa fa-venus-mars"></i></h2></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-none d-sm-none d-md-block">
                                <div class="hairnskin-img common-img" data-aos="fade-up" data-aos-delay="0">
                                    <img src="img/others/hairnskin-img.webp" class="img-fluid" alt="post" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="depression-holder common-holder">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="depression-img common-img" data-aos="fade-up" data-aos-delay="0">
                                    <img src="img/others/depression-img.webp" class="img-fluid" alt="post" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="depression-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                    <h1>Additional Treatments</h1>
                                    <p>We believe a happy life is a good life. That's why we're here to help you with a <b>BETTER</b> Tomorrow with Better-Doctor.</p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="sub">
                                                <a href="https://getbetterdoctor.com/forms/dist/#/depression/initial/1"><h2>Depression  <i class="fa fa-venus-mars"></i></h2></a>
                                                <a href="https://getbetterdoctor.com/forms/dist/#/weight/loss/1"><h2>Weight Loss  <i class="fa fa-venus-mars"></i></h2></a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="sub">
                                                <a href="https://getbetterdoctor.com/forms/dist/#/smoking/1"><h2>Quit Smoking  <i class="fa fa-venus-mars"></i></h2></a>
                                                <a href="https://getbetterdoctor.com/forms/dist/#/allergies/1"><h2>Allergies <i class="fa fa-venus-mars"></i></h2></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="how-holder">
                        <div class="container">
                            <div class="how-heading heading text-center" data-aos="fade-up" data-aos-delay="0">
                                <h1>How it works</h1>
                                <p>Better-Doctor handles everything from online evaluation to delivery of treatment and free ongoing care.</p>
                            </div>

                            <div class="howit-wrap">
                                <div class="row m-0">
                                    <div class="col-md-4 p-0">
                                        <div class="howit-cont-wrap one" data-aos="fade-up" data-aos-delay="0" >
                                            <div class="howit-img">
                                                <img src="img/others/howit-img-1.webp" class="img-fluid rounded-circle" alt="howit-img-1">
                                             <div class="howit-num">
                                                    <h3>1</h3>
                                                </div>
                                            </div>
                                            <div class="howit-contents">
                                                <h3>Online visit</h3>
                                                <p>Easy Online Visit, Reviewed by Certified Medical Professionals, Designed to find the <b>BETTER</b> treatment option for you.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 p-0">
                                        <div class="howit-cont-wrap two" data-aos="fade-up" data-aos-delay="0">
                                            <div class="howit-img">
                                                <img src="img/others/howit-img-2.webp" class="img-fluid  rounded-circle" alt="howit-img-1">
                                                <div class="howit-num two">
                                                    <h3>2</h3>
                                                </div>
                                            </div>
                                            <div class="howit-contents">
                                                <h3>Fast deliveries</h3>
                                                <p>Fast Shipping of any treatments prescribed<br> by your <b>BETTER</b> Doctor. So you can start your <b>BETTER</b> life!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 p-0">
                                        <div class="howit-cont-wrap three" data-aos="fade-up" data-aos-delay="0">
                                            <div class="howit-img">
                                                <img src="img/others/howit-img-3.webp" class="img-fluid  rounded-circle" alt="howit-img-1">
                                                <div class="howit-num">
                                                    <h3>3</h3>
                                                </div>
                                            </div>
                                            <div class="howit-contents">
                                                <h3>Ongoing care</h3>
                                                <p>Our Medical Professionals are available around the clock to answer any questions or concerns.  We always have you covered for your <B>BETTER</b> Tomorrow! </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="why-holder">
                        <div class="container">
                            <div class="why-heading heading text-center" data-aos="fade-up" data-aos-delay="0">
                                <h1>Why Better-Doctor?</h1>
                                <p>We enable you to have a <b>BETTER</b> Tomorrow by using our innovative online technoloy, and innovative lab partners.</p>
                            </div>
                            <div class="why-contents">
                                <div class="row">
                                    <div class="col-4 col-sm-4 col-md-4">
                                        <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                            <div class="row m-0">
                                                <div class="col-md-4 p-0">
                                                    <div class="why-icon">
                                                        <img src="img/icons/why-1.webp" class="img-fluid" alt="icon" />
                                                    </div>
                                                </div>
                                                <div class="col-md-8 p-0">
                                                    <div class="why-details">
                                                        <h6>Easy to use online Visit</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-sm-4 col-md-4">
                                        <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                            <div class="row m-0">
                                                <div class="col-md-4 p-0">
                                                    <div class="why-icon">
                                                        <img src="img/icons/why-2.webp" class="img-fluid" alt="icon" />
                                                    </div>
                                                </div>
                                                <div class="col-md-8 p-0">
                                                    <div class="why-details">
                                                        <h6>Certified Medical Professionals</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-sm-4 col-md-4">
                                        <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                            <div class="row m-0">
                                                <div class="col-md-4 p-0">
                                                    <div class="why-icon">
                                                        <img src="img/icons/why-3.webp" class="img-fluid" alt="icon" />
                                                    </div>
                                                </div>
                                                <div class="col-md-8 p-0">
                                                    <div class="why-details">
                                                        <h6>Control your well being</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4 col-sm-4 col-md-4">
                                        <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                            <div class="row m-0">
                                                <div class="col-md-4 p-0">
                                                    <div class="why-icon">
                                                        <img src="img/icons/why-4.webp" class="img-fluid" alt="icon" />
                                                    </div>
                                                </div>
                                                <div class="col-md-8 p-0">
                                                    <div class="why-details">
                                                        <h6>Personalized Treatments</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-sm-4 col-md-4">
                                        <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                            <div class="row m-0">
                                                <div class="col-md-4 p-0">
                                                    <div class="why-icon">
                                                        <img src="img/icons/why-5.webp" class="img-fluid" alt="icon" />
                                                    </div>
                                                </div>
                                                <div class="col-md-8 p-0">
                                                    <div class="why-details">
                                                        <h6>Secure and Confidential</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-sm-4 col-md-4">
                                        <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                            <div class="row m-0">
                                                <div class="col-md-4 p-0">
                                                    <div class="why-icon">
                                                        <img src="img/icons/why-6.webp" class="img-fluid" alt="icon" />
                                                    </div>
                                                </div>
                                                <div class="col-md-8 p-0">
                                                    <div class="why-details">
                                                        <h6>24/7 Access</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="testing-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class="mobilebutton btn filled-btn testing-btn hvr-wobble-vertical"><i class=" fa fa-stethoscope" aria-hidden="true"></i> Get Started!</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="start-holder">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="start-contents heading" data-aos="fade-up" data-aos-delay="0" >
                                        <h1>Ready to start your <b>BETTER</b> tomorrow?</h1>
                                        <p>If something is bothering you, we want to help. Start a visit to get started.</p>
                                        <a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class="mobilebutton btn start-btn common-btn hvr-push">Get Started!</a>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="start-info">
                                        <div class="row">
                                            <div class="col-6 col-sm-6 col-md-6">
                                                <div class="start-box text-center" data-aos="fade-up" data-aos-delay="0" >
                                                    <img src="img/icons/start-1.webp" class="img-fluid" alt="icon" />
                                                    <h6  >Fast <br> shipping</h6>
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6 col-md-6">
                                                <div class="start-box text-center" data-aos="fade-up" data-aos-delay="0">
                                                    <img src="img/icons/start-2.webp" class="img-fluid" alt="icon" />
                                                    <h6  >Easy <br> visit</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 col-sm-6 col-md-6">
                                                <div class="start-box text-center" data-aos="fade-up" data-aos-delay="0">
                                                    <img src="img/icons/start-3.webp" class="img-fluid" alt="icon" />
                                                    <h6>No long <br> term Commitment</h6>
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6 col-md-6">
                                                <div class="start-box text-center" data-aos="fade-up" data-aos-delay="0">
                                                    <img src="img/icons/start-4.webp" class="img-fluid" alt="icon" />
                                                    <h6  >Free <br> follow-ups</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Treatments",
methods : {

        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>

<style scoped>

</style>