<template>
    <div class="modal" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="" aria-hidden="true">
      <div class="modal-header">
        <h5 class="modal-title">Drug Fact</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="" role="document">
            <div class="modal-body">
                <div>
                    <h1>Patient Education - Sildenafil 50 Mg Tablet</h1>
                    <h2>CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit</h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2><br>
                </div>
                <div>
                    <br>
                    <p>
                        CONSUMER INFORMATION USE AND DISCLAIMER This information should not be used to decide whether or not to take this medicine or any other medicine. Only
                        the healthcare provider has the knowledge and training to decide which medicines are right for a specific patient. This information does not endorse any medicine as safe,
                        effective, or approved for treating any patient or health condition. This is only a brief summary of general information about this medicine. It does NOT include all
                        information about the possible uses, directions, warnings, precautions, interactions, adverse effects, or risks that may apply to this medicine. This information is not specific
                        medical advice and does not replace information you receive from the healthcare provider. You must talk with the healthcare provider for complete information about the
                        risks and benefits of using this medicine.
                    </p>
                    <span>
                        Sildenafil Tablets (Erectile Dysfunction) <br>
                        PRONUNCIATION (sil DEN a fil)
                    </span>
                    <span>
                        BRAND NAME(S): Viagra
                    </span>
                    <p>
                       WHAT IS THIS DRUG USED FOR? It is used to treat erectile dysfunction (ED).; It may be given to you for other reasons. Talk with the doctor.
                    </p>
                    <p>
                        WHAT DO I NEED TO TELL MY DOCTOR BEFORE I TAKE THIS DRUG? If you have an allergy to sildenafil or any other part of this drug.; If you are allergic to any
                        drugs like this one, any other drugs, foods, or other substances. Tell your doctor about the allergy and what signs you had, like rash; hives; itching; shortness of breath;
                        wheezing; cough; swelling of face, lips, tongue, or throat; or any other signs.; If you have a health problem called pulmonary veno-occlusive disease (PVOD).; If you use
                        drugs called "poppers" like amyl nitrite and butyl nitrite.; If you are taking any of these drugs: Isosorbide dinitrate, isosorbide mononitrate, nitroglycerin, riociguat, or
                        ritonavir.; If you are taking another drug that has the same drug in it.; If you are taking another drug like this one that is used to treat erectile dysfunction (ED) or high
                        pressure in the lungs.; If you have been told that you are not healthy enough to have sex.; If you are a woman. This drug is not approved for use in women.; If the patient is
                        a child. This drug is not approved for use in children.This is not a list of all drugs or health problems that interact with this drug.Tell your doctor and pharmacist about all of
                        your drugs (prescription or OTC, natural products, vitamins) and health problems. You must check to make sure that it is safe for you to take this drug with all of your drugs
                        and health problems. Do not start, stop, or change the dose of any drug without checking with your doctor.
                    </p>
                    <p>
                        WHAT ARE SOME THINGS I NEED TO KNOW OR DO WHILE I TAKE THIS DRUG? Tell all of your health care providers that you take this drug. This includes your
                        doctors, nurses, pharmacists, and dentists. ; Avoid driving and doing other tasks or actions that call for you to be alert until you see how this drug affects you.; To lower the
                        chance of feeling dizzy or passing out, rise slowly if you have been sitting or lying down. Be careful going up and down stairs.; Talk with your doctor before you drink
                        alcohol.; Rarely, certain side effects like abnormal heartbeat, heart attack, stroke, bleeding in the lungs, and very bad high blood pressure have happened with this drug.
                        Sometimes, this has been deadly. Most of the time, these problems happened in people who had heart problems before taking this drug. It is not known if this drug caused
                        these problems. If you have questions, talk with the doctor.; A very bad eye problem has rarely happened with this drug. This may lead to a change in eyesight and
                        sometimes loss of eyesight, which may not come back. Talk with the doctor.; If you are 65 or older, use this drug with care. You could have more side effects.; This drug
                        does not stop the spread of diseases like HIV or hepatitis that are passed through having sex. Do not have any kind of sex without using a latex or polyurethane condom. If
                        you have questions, talk with your doctor.
                    </p>
                    <p>
                        WHAT ARE SOME SIDE EFFECTS THAT I NEED TO CALL MY DOCTOR ABOUT RIGHT AWAY? WARNING/CAUTION: Even though it may be rare, some people
                        may have very bad and sometimes deadly side effects when taking a drug. Tell your doctor or get medical help right away if you have any of the following signs or
                        symptoms that may be related to a very bad side effect: Signs of an allergic reaction, like rash; hives; itching; red, swollen, blistered, or peeling skin with or without fever;
                        wheezing; tightness in the chest or throat; trouble breathing, swallowing, or talking; unusual hoarseness; or swelling of the mouth, face, lips, tongue, or throat.; Chest pain or
                        pressure or a fast heartbeat.; A heartbeat that does not feel normal.; Dizziness or passing out.; Very bad headache.; Very upset stomach or throwing up.; Weakness on 1
                        side of the body, trouble speaking or thinking, change in balance, drooping on one side of the face, or blurred eyesight.; Change in eyesight.; Loss of eyesight.; Change in
                        hearing.; Hearing loss.; Ringing in ears.; Shortness of breath.; Swelling in the arms or legs.; Fever.; Any unexplained bruising or bleeding.; Call your doctor right away if
                        you have a painful erection (hard penis) or an erection that lasts for longer than 4 hours. This may happen even when you are not having sex. If this is not treated right away,
                        it may lead to lasting sex problems and you may not be able to have sex.
                    </p>
                    <p>
                        WHAT ARE SOME OTHER SIDE EFFECTS OF THIS DRUG? All drugs may cause side effects. However, many people have no side effects or only have minor side
                        effects. Call your doctor or get medical help if any of these side effects or any other side effects bother you or do not go away: Flushing.; Headache.; Heartburn.; Upset
                        stomach.; Stuffy nose.; Runny nose.; Muscle pain.; Back pain.These are not all of the side effects that may occur. If you have questions about side effects, call your doctor.
                        Call your doctor for medical advice about side effects.You may report side effects to the FDA at 1-800-FDA-1088. You may also report side effects at
                        http://www.fda.gov/medwatch.
                    </p>
                    <p>
                        HOW IS THIS DRUG BEST TAKEN? Use this drug as ordered by your doctor. Read all information given to you. Follow all instructions closely.; Take with or without
                        food.; Take this drug before sex when you were told by your doctor. If you are not sure when to take this drug, talk with your doctor.; Do not use more than what your
                        doctor told you to use. Do not use more often or longer than what you were told. Doing any of these things may raise the chance of very bad side effects.
                    </p>
                    <p>
                      WHAT DO I DO IF I MISS A DOSE? This drug is used on an as needed basis. Do not use more often than told by the doctor.; Do not take 2 doses on the same day.
                    </p>
                    <p>
                        HOW DO I STORE AND/OR THROW OUT THIS DRUG? Store at room temperature.; Store in a dry place. Do not store in a bathroom.; Keep all drugs in a safe place.
                        Keep all drugs out of the reach of children and pets.; Throw away unused or expired drugs. Do not flush down a toilet or pour down a drain unless you are told to do so.
                        Check with your pharmacist if you have questions about the best way to throw out drugs. There may be drug take-back programs in your area.
                    </p>
                    <p>
                        GENERAL DRUG FACTS If your symptoms or health problems do not get better or if they become worse, call your doctor. ; Do not share your drugs with others and do
                        not take anyone else's drugs.; Keep a list of all your drugs (prescription, natural products, vitamins, OTC) with you. Give this list to your doctor.; Talk with the doctor before
                        starting any new drug, including prescription or OTC, natural products, or vitamins.; Some drugs may have another patient information leaflet. Check with your pharmacist.
                        If you have any questions about this drug, please talk with your doctor, nurse, pharmacist, or other health care provider.; If you think there has been an overdose, call your
                        poison control center or get medical care right away. Be ready to tell or show what was taken, how much, and when it happened.
                    </p>
                    <span style="border-bottom: 2px solid black">Copyright 2021 UpToDate, Inc. All Rights Reserved. (Issue 21.4.3.005)</span>
                    <br><br><br>
                    <h2>
                        CALL YOUR DOCTOR FOR ANY QUESTIONS OR CONCERNS AT +1 (888)-286-8124 or visit
                    </h2>
                    <h3><a href="www.getbetterdoctor.com">www.getbetterdoctor.com</a></h3>
                    <h2>MONDAY - FRIDAY 9:00AM - 5:00PM, SATURDAY 9:00AM - 12:00PM (EASTERN TIME)</h2>
                    <p>
                        Under certain circumstances a prescription drug's effectiveness may be affected by exposire to extremes of heat, cold, or humidty.
                        Where should i store my medicine?
                    </p>
                    <p>
                        Store at controlled room temperature. Store at 20 degrees celsius to 25 degrees celsuis, excursions permitted between 15 degrees celsius and 30 degrees celsius. Brief exposure to
                        temperatures up to 40 degrees celsius may be tolerated provied the mean kinetic temperature does not exceed 25 degrees celsius; however, such exposure should be minimized.
                        The product is to be transferred to proper storage conditions without delay, as instructed by the package insert, ideally within 2 hours of receipt.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "modal4",
}
</script>

<style lang="scss" scoped>
.col-3 {
    max-width: 30%;
}
.col-9 {
    max-width: 70% ;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: silver; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black; 
}
.modal{
    width: 50vw;
    position: fixed;
    left: 25%;
    height: 50vw;
    border-radius: 25px;
    // border-radius: 10px;
    background-color: white;
    padding: 20px 20px;
    z-index: 1200;
    vertical-align: middle !important;
    top: 5px;
        &__wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
    }
}
.modal-dialog {
    max-width: auto;
}
.modal-header {
    background-color: #0EB8B7;
    color: white;
    font-weight: bold;
}
.modal-body {
    width: auto;
    z-index: 1111;
    background-color: white !important;
}
.popup {
    position: absolute;
    left: 50%;
    margin-left: -306px;
    top: 100px;
    width: 612px;
    height: 792px;
    border-style: outset;
    overflow: hidden;
}
.popup2 {
    position:absolute;
    left:50%;
    margin-left:-306px;
    top:802px;
    width:612px;
    height:792px;
    border-style:outset;
    overflow:hidden
}
.modal-backdrop.fade {
    opacity: 0 !important ;
}
.modal-backdrop.show {
    opacity: 0 !important;
}
@media only screen and (min-width: 726px) and (max-width: 900px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1280px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 1300px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
@media only screen and (max-width: 726px) {
    .col-3 {
        max-width: 100% !important;
    }
    img {
        width: 150px;
    }
    .col-9 {
        max-width: 100% !important;
    }
    h1 {
        font-size: 23px !important;
    }
    h2 {
        font-size: 18px !important;
    }
    h3 {
        font-size: 15px !important;
    }
    .modal{
        width: 95vw;
        position: fixed;
        height: 92vh;
        left: 2%;
        border-radius: 25px;
        // border-radius: 10px;
        background-color: white;
        padding: 20px 20px;
        z-index: 1200;
        vertical-align: middle !important;
        top: 2rem;
        &__wrapper{
        // display: flex;
        // justify-content: center;
        // align-items: center;
        z-index: 1000;
        position: absolute;
        top: 10;
        }
    }
    .popup {
        position: absolute;
        top: 0px;
        left: 0%;
        margin-left: 0px;
        width: 85vw;
        height: 792px;
        border-style: outset;
        overflow: hidden;
    }
    .popup2 {
        position:absolute;
        left: 0%;
        margin-left: 0px;
        top:802px;
        width: 85vw;
        height:792px;
        border-style:outset;
        overflow:hidden
    }
    .modal-header {
        background-color: #0EB8B7;
        color: white;
    }
    .modal-body {
        width: auto;
        z-index: 1111;
        background-color: white !important;
    }
    .modal-backdrop.fade {
        opacity: 0 !important ;
    }
    .modal-backdrop.show {
        opacity: 0 !important;
    }
}
</style>