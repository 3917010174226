<template>
    <div>
        <example-modal7 ref="modal"></example-modal7>
        <header>
            <div class="header-bottom weightloss-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Achieve a Better You!</h1>                                    
                                    <p>From healthy diet plans to helpful weight loss tools, here you'll find BetterDoctor's latest diet news and information.</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                <div class="banner-features">                              
                                    <ul class="list-unstyled d-flex d-inline-flex m-0">
                                        <li class="pixel-2 pixel-3">
                                            <span>
                                                <img src="img/icons/feature-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            U.S. Board Certified <br/> Professionals
                                        </li>
                                        <li class="pixel-2 pixel2 banner-1-icon">
                                            <span class="button-icon-pd">
                                                <img src="img/icons/depression-bannericon-1.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Secure and <br/> Confidential
                                        </li>
                                        <li class="pixel-2">
                                            <span>
                                                <img src="img/icons/treatment-bannericon-3.webp" class="img-fluid" alt="icon" />
                                            </span>
                                            Avoid Expensive <br/> Doctor Visits
                                        </li>
                                    </ul>
                                    <br>
                                </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a :href='started_weightloss' class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/weightloss-1.png" class="img-fluid" width="550px" alt="post" />
                            </div>
                        </div>                          
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Weight Loss</h1>
                                <h2>7/80mg Active Ingredients Naltrexone and Bupropion</h2>
                                <p>Studies show the combination of naltrexone and bupropion Suppresses appetite and helps control cravings by affecting two areas of the brain. This makes it an effective medicine for losing weight and keeping it off. Each medication works by blocking the "reward" response and reducing desire for the specific substances.</p>
                                <p>Naltrexone is prescribed independently to prevent alcohol and drug relapse. It works by blocking the reward center associated with opioids and alcohol and has been shown to block the "association between trait-like reward-driven eating and daily food-craving."</p>
                                <p>Bupropion belongs to the class of antidepressants norepinephrine-dopamine reuptake inhibitors (NDRI). Doctors may also prescribe it as a smoking cessation treatment.</p>
                                <p>To ensure that we provide you with the best price, we may substitute one generic for another.</p>
                                <h2>Reference</h2>
                                <ul>
                                    <li>1 Mason AE, Laraia B, Daubenmier J, et al. Putting the brakes on the "drive to eat": Pilot effects of naltrexone and reward-based eating on food cravings among obese women. Eat Behav. 2015;19:53‐56. doi:10.1016/j.eatbeh.2015.06.008</li>
                                </ul>                                    
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">TRANSPARENT PRICING</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border price-pd-right">
                                        <p class="price-alignment bbold">Weight Loss</p>
                                        <h1 class="price-alignment bbold">$1.50</h1>
                                        <span class="price-alignment price-sub bbold">Per Dose</span>
                                        <p class="price-alignment facts-button"><a @click="showModal7">Drug Facts</a></p>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a :href='started_weightloss' class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br><br><br><br><br>
                                <img src="img/others/weightloss-1.png" class="img-fluid" width="550px" alt="post" />
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Config from '../../config/prod.env';
import exampleModal7 from "../modal/weightloss.vue";


export default {
    name: "herpes",
    components: {
            Header,
            "example-modal7":exampleModal7,
        },
    data() {
        return {
            started_weightloss: Config.STARTED_WEIGHTLOSS,
        }
    },      
    methods : {
        weightloss: function(){
        var pdf = '/pdf/Weightloss/Naltrexone_Bupropion.pdf';
        window.open(pdf);
        },
        showModal7() {
            let element = this.$refs.modal.$el
            $(element).modal('show')   
        },
        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}
.facts-button {
    cursor: pointer;
    font-weight: bold;
    color: #0EB8B7;
    text-decoration: underline ;
}
@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 130px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 112px;
    }
}

@media only screen and (min-width: 413px) and (max-width:414px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 104px !important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 766px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 96px;
    }
}

@media only screen and (max-width: 767px) {
    .hideDivMb{
        display: none !important;
    }
    .showDivMb {
        display: block !important;
    }
}

@media only screen and (max-width: 1199px) {
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
    .price-alignment {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
    .col03 {
        width: 80%;
    }
}
@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -12px !important;
    } 
    .pixel-3 {
        margin-left: 5px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 410px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 8px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -5px !important;
    } 
    .pixel-3 {
        margin-left: 22px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 319px) and (max-width: 320px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -6px !important;
    } 
    .pixel-3 {
        margin-left: 4px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -8px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -9px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -10px !important;
    } 
    .pixel-3 {
        margin-left: 13px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 27px !important;
    }
    .pixel2 {
        margin-left: -9px !important;
    } 
    .pixel-3 {
        margin-left: 15px;
    }
    .pixel-02 {
        margin-left: -7px !important;
    }
}
</style>