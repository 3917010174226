<template>
    <div>
        <header>
            <div class="header-bottom herpes-banner">
                <div class="banner-wrap banner-color">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Home STD Testing with Treatment Included</h1>
                                    <br>
                                    <p>Looking for peace of mind without the trip to the clinic? Try at-home STD testing for fast results. Start treatment discreetly with medication provided at no additional charge.</p>
                                </div>
                            </div>
                            <div class="col-md-6" style="justify-content:center">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <div class="banner-features">
                                        <ul class="list-unstyled d-flex d-inline-flex m-0">
                                            <li class="surfaceduo surface-duo pixel2 pixel-2">
                                            <span>
													<img src="img/icons/labtesting-bannericon-1.webp" class="img-fluid" alt="icon" />
												</span> At-Home Testing
                                            </li>
                                            <li class="surface-duo pixxel-2 pixel-2 banner-2-icon">
                                            <span>
													<img src="img/icons/labtesting-bannericon-2.webp" class="img-fluid" alt="icon" />
												</span> In-Person Testing
                                            </li>
                                            <li class="pixel-2 surface-duo">
                                            <span>
													<img src="img/icons/labtesting-bannericon-3.webp" class="img-fluid" alt="icon" />
												</span> CLIA Certified Labs
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><span class=" buttonstyle btn filled-btn">Coming Soon</span></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 showDivMb" style="display:none">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/std1.png" class="img-fluid" height="400px" alt="post" />
                            </div>
                        </div>                            
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Personalized online dashboard</h1>
                                <p class="bbold">Secure</p>
                                <p>Your privacy is our priority. Take full control of your testing with confidential results available on your password-protected account.</p>
                                <p class="bbold">Easy-to-understand</p>
                                <p>No more confusing health reports. We provide detailed result breakdowns, helpful next steps and factsheets created by our team of experts.</p>
                                <p class="bbold">Empowering</p>
                                <p>Peace of mind is powerful. Your sexual health results provide you with the insights you need to take action and avoid any unwanted health issues down the line.</p>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb ">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <img src="img/others/std1.png" class="img-fluid" alt="post" />
                            </div>
                        </div>                        
                    </div>
                    <div style="background-color: #F8F8F8">
                        <div class="col-lg-12" style="justify-content:center; display:flex">
                            <h1 class="sub-heading" style="color: #474747; padding-top:20px">How It Works</h1>
                        </div>
                    </div>
                    <br><br>
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">1</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Order your test</h2>
                            <p>All of our home collection kits and medication are delivered in a plain envelope with no reference to Better Dr. visible, for a private testing experience.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/std-step1.jpg" class="img-width img-fluid img-pd" width="400px" alt="post" />
                        </div>
                    </div>
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">2</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Collect your sample</h2>
                            <p>Activate your test and collect your sample in the morning. Return your sample free of charge using the prepaid shipping label provided.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/std-step2.jpg" class="img-width img-fluid img-pd" width="400px" height="200px"  alt="post" />
                        </div>
                    </div>      
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">3</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Receive accurate results</h2>
                            <p>Once your sample arrives in the laboratory, confidential results will be available from your secure online account within 2 to 5 days.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/std-step-3.jpg" class="img-width img-fluid img-pd" width="400px" alt="post" />
                        </div>
                    </div>                 
                    <div class="row pd-top2">
                        <div class="col-lg-1 col-2">
                            <span class="dot text-center" style="padding-top:5px">4</span>
                        </div>
                        <div class="col-lg-5 col-10">
                            <h2>Get support & treatment</h2>
                            <p>If your results are positive, our nurses will call you to explain your results and arrange for medication to be sent to your home, if suitable.</p>
                        </div>
                        <div class="col-lg-6">
                            <img src="img/others/std-step4.jpg" class="img-width img-fluid img-pd" height="200px" width="400px" alt="post" />
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
export default {
    name: "std",
    components: {Header},
    methods : {

        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}

@media only screen and (min-width: 1200px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .pd-top2 {
        padding-top: 50px;
    }
    .img-pd {
        margin-left: 50px;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
    }
}

@media only screen and (max-width: 950px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .hideDivMb{
        display: none !important;
    }
    .showDivMb {
        display: block !important;
    }
}

@media only screen and (max-width: 768px) {
    .price-alignment {
        display: flex;
        justify-content: center;
    }
    .price-sub {
        font-size: 13px;
    }
    .heading h1::after {
        height: 0px !important;
    }
    .price-border {
        border: 1px solid #0EB8B7;
        border-radius: 10px;
        padding-right: 5px ;
        margin-left: 90px;
    }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .col03 {
    width: 80%;
  }
}
.dot {
  height: 50px;
  width: 50px;
  background-color: #0eb8b7;
  border-radius: 50%;
  display: inline-block;
  font-size: 23px;
  color: white;
}

@media only screen and (max-width: 280px) {
    .col-2 {
        max-width: 25% !important;
    }
    .col-10 {
        max-width: 73% !important;
    }
    .sub-heading {
        margin-left: 46px;
    }
}

@media only screen and (min-width: 410px) and (max-width: 411px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
}
@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 19px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
    .pixxel-2 {
        margin-right: 13px !important;
    }   
}
@media only screen and (min-width: 412px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 23px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }       
}
@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 18px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }   
}
@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 18px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }   
}
@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 19px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }    
}
@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 18px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }    
}
@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 17px !important;
    }
    .pixel2 {
        margin-left: 21px !important;
    } 
    .pixxel-2 {
        margin-right: 11px !important;
    }
}
@media only screen and (min-width: 767px) and (max-width: 900px) {
    .img-width {
        width: 700px !important;
    }
}

@media only screen and (min-width: 539px) and (max-width: 540px) {
    .surface-duo {
        margin-right: 17px !important;
    }
    .img-width {
        width: 700px !important;
    }
    .surfaceduo {
        margin-left: 23px !important;
    } 
}
</style>