<template>
    <div>
        <div class="holder">
            <div class="innerfaq-holder faq-section">
                <div class="container">
                    <div class="innerfaq-heading heading text-center"  data-aos="fade-up" data-aos-delay="0">
                        <h1>Frequently Asked Questions</h1>
                        <p>For any additional questions, please reach out to <a href="mailto:support@getbetterdoctor.com"> support@getbetterdoctor.com. </a> We can also be reached by phone at <a href="tel:+1 (888)-286-8124"> +1 (888)-286-8124</a>, Monday – Friday, 09:30am - 09:00pm CST.</p>
                    </div>
                    <div class="inner-faq"  data-aos="fade-up" data-aos-delay="0">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="nav nav-pills faq-nav" id="faq-tabs" role="tablist" aria-orientation="vertical">
                                    <a href="#tab1" class="nav-link active show" data-toggle="pill" role="tab" aria-controls="tab1" aria-selected="false">
                                        Telehealth
                                    </a>
                                    <a href="#tab2" class="nav-link" data-toggle="pill" role="tab" aria-controls="tab2" aria-selected="false">
                                        Erectile dysfunction
                                    </a>
                                    <a href="#tab3" class="nav-link" data-toggle="pill" role="tab" aria-controls="tab3" aria-selected="false">
                                        Premature ejaculation
                                    </a>
                                    <a href="#tab4" class="nav-link" data-toggle="pill" role="tab" aria-controls="tab4" aria-selected="true">
                                        Hair loss
                                    </a>
                                    <a href="#tab5" class="nav-link" data-toggle="pill" role="tab" aria-controls="tab5" aria-selected="false">
                                        Cold sores
                                    </a>
                                    <a href="#tab6" class="nav-link" data-toggle="pill" role="tab" aria-controls="tab6" aria-selected="false">
                                        Allergies
                                    </a>
                                    <a href="#tab7" class="nav-link" data-toggle="pill" role="tab" aria-controls="tab7" aria-selected="false">
                                        Eczema
                                    </a>
                                    <a href="#tab8" class="nav-link" data-toggle="pill" role="tab" aria-controls="tab8" aria-selected="false">
                                        Dandruff
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8">
                                <div class="tab-content faq-contents" id="faq-tab-content">
                                    <div class="tab-pane active show" id="tab1" role="tabpanel" aria-labelledby="tab1">
                                        <div class="faq-head">
                                            <h3>Telehealth</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-1">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse5" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What is Better-Doctor?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse5" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Better-Doctor is most revolutionary consumer healthcare driven platform that offers At Home Testing, In Lab Testing and Treatment Programs in one place. It’s simply the best tests and treatments for a BETTER you!  We have built a platform that allows you to take control of your own healthcare from the convenience of your home.</p>
                                                    </div>
                                                </div>
                                            </div><div class="card">
                                            <div class="panel-heading">
                                                <a class="card-header collapsed" data-toggle="collapse" href="#collapse6" data-parent="#accordion-tab-1" aria-expanded="false">
                                                    <h4 class="card-title">
                                                        <span>Do I need to have a prescription to receive medication from Better-Doctor?</span>
                                                        <i class="indicator fa fa-chevron-circle-right"></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div id="collapse6" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                <div class="card-body">
                                                    <p>No. The licensed medical professionals on our platform will write your prescription after your online visit if they decide it is appropriate for you.</p>
                                                </div>
                                            </div>
                                        </div><div class="card">
                                            <div class="panel-heading">
                                                <a class="card-header collapsed" data-toggle="collapse" href="#collapse7" data-parent="#accordion-tab-1" aria-expanded="false">
                                                    <h4 class="card-title">
                                                        <span>Who are the licensed medical professionals on Better-Doctor?</span>
                                                        <i class="indicator fa fa-chevron-circle-right"></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div id="collapse7" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                <div class="card-body">
                                                    <p>All of the physicians, physicians assistants and nurse practitioners on Better-Doctor are U.S. licenses healthcare professionals that have gone through an extensive background check and verification process.</p>
                                                </div>
                                            </div>
                                        </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse21" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need to see a doctor in person to use Better-Doctor’s services?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse21" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>In order to receive some of the services on our platform you need to have seen a doctor in the last 3 years.   Some of the testing/lab services can be used immediately without having seen a doctor recently.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse22" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is it legal to prescribe medication online?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse22" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Yes. Our platform enables physicians to practice medicine and communicate with patients remotely. All of our physicians are licensed to prescribe medicine and treat patients in your state.  Better-Doctor complies with all governing medical laws in the US.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse23" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is it safe to receive treatment from Better-Doctor?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse23" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Yes, we rely on the patient to answer questions accurately and truthfully to the best of their ability.  We also require that you upload pictures of yourself and your ID.  The photo should be taken during the online visit.  Our healthcare professionals can only handle your needs effectively when they have accurate and current information about you and your case.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse24" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How quickly will a healthcare professional review my case?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse24" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Within 24 hours of your online visit, a healthcare professional will complete the initial review of your medical history.  If your case in not reviewed in that time period, please email <a href="mailto:support@getbetterdoctor.com">support@getbetterdoctor.com.</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse25" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Does Better-Doctor replace my primary care physician?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse25" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>No.  Better-Doctor supplements the care you receive from your primary care physician.  We recommend that you keep your primary care physician updated on the care you have received through Better-Doctor.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse26" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Does everyone have the chance to talk to a doctor?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse26" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Absolutely. After you complete your initial dynamic onboarding you can message a physician any time you have a question. Also, if you want to chat with the doctor or nurse practitioner via a secure phone call or video chat, message our medical support team and we’ll make it happen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse27" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I video chat with a doctor?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse27" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>After you’ve finished your initial online visit, and a physician or nurse practitioner has decided you’re a good candidate for telemedicine, you can speak with a physician or nurse practitioner via messaging, phone call, or a video conference any time you like.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse28" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is video chat required to receive care?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse28" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>It depends on where you live.  Some states require video chat with your healthcare professional.  After your initial online (non-video) visit, a healthcare professional will reach out with next steps.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse29" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>If I am having a medical emergency, what do I do?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse29" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Please call <a href="tel:911">911</a> immediately. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse30" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Why do you need a picture of my ID and separate picture?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse30" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Your doctor or nurse practitioner will need to confirm your identity before treating you via telemedicine. In order to confirm your identity, we need two photos:</p>
                                                        <p> 1. A photo of your government-issued ID</p>
                                                        Must include:
                                                        <ul>
                                                            <li>First & Last name</li>
                                                            <li>Photo</li>
                                                            <li>DOB</li>
                                                            <li>ID expiration date (cannot be expired)</li>
                                                        </ul>
                                                        <p>2. A clear photo of your face</p>
                                                        Cannot include:
                                                        <ul>
                                                            <li>Sunglasses, hats, or other facial obstructions</li>
                                                            <li>Family photos</li>
                                                            <li>Filters</li>
                                                            <li>Pictures of a picture</li>
                                                        </ul>
                                                        <p>We’ll use these photos only to confirm that the pictures match each other.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse1" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How do I manage my orders?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse1" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>You are able to pause or cancel upcoming shipments through the portal.  Our shipments come Quarterly (every 3 months). If regular shipments are not right for you, you can also switch to On-Demand shipments after you receive your first order. Additionally, you can pause or delay individual shipments.
                                                            We send email reminders 24 hours prior to processing a refill to allow for any adjustments. To make updates to your plan or pause/cancel any orders, please visit your account on the portal.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse2" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I return my shipment?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse2" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Unfortunately, we cannot accept returns of prescription products for reuse or resale, and all sales are final. If you feel we have made an error in the filling of your prescription, please contact us at <a href="mailto:support@getbetterdoctor.com">support@getbetterdoctor.com </a> </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse3" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need to sign for my delivery?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse3" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>No, signatures are not required.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header collapsed" data-toggle="collapse" href="#collapse4" data-parent="#accordion-tab-1" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>When can I expect to receive my order?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse4" class="collapse" aria-expanded="false" data-parent="#accordion-tab-1" style="">
                                                    <div class="card-body">
                                                        <p>Once your order is shipped you will receive an email link to track your shipment.
                                                            Orders are shipped on weekdays; orders placed after 5 PM will ship next day.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab2" role="tabpanel" aria-labelledby="tab2">
                                        <div class="faq-head">
                                            <h3>Erectile dysfunction</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-2">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse31" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does ED treatment work on the Better-Doctor platform?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse31" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>Better-Doctor uses telemedicine technology to provide a discreet, convenient, and affordable way for you to receive erectile dysfunction treatment from a U.S. Licensed physician. All without leaving the couch. Complete the dynamic onboarding, submit your photo, ID, and recent blood pressure reading, and our physicians will determine if you’re a candidate for telemedicine.</p>
                                                        <br>
                                                        <p>Erectile dysfunction is often a symptom of a more serious underlying condition—like heart disease, diabetes, high blood pressure, or even depression. That’s why we strongly recommend optional laboratory tests.</p>
                                                        <br>
                                                        <p>These tests aren’t required to receive treatment on the Better-Doctor platform. But they help identify the underlying cause of your ED. We want you to get the most comprehensive treatment possible. That’s why we’ll analyze your results for free. Just send us your most recent blood test and we’ll add it to your account information to help our physicians give you the most comprehensive care from the convenience of your phone.</p>
                                                        <br>
                                                        <p>It might sound funny but regular erections are a great barometer of your overall health. Learn why paying attention to the strength and frequency of your erection might actually save your life.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse32" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Which medications do doctors or nurse practitioners on the Better-Doctor platform prescribe for ED?</span>
                                                            <i style="margin-top:-35px !important;" class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse32" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>The American Urological Association claims that oral PDE-5 inhibitors should be offered as the “first line of therapy for erectile dysfunction.” So that’s what we do.</p>
                                                        <br>
                                                        <p>Physicians or nurse practitioners on the Better-Doctor Platform prescribe the most common PDE-5 oral medications which include:</p>
                                                        <ul>
                                                            <li>Sildenafil (Viagra)</li>
                                                            <li>Tadalafil (Cialis)</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse33" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is ED medication effective?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse33" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>Viagra’s FDA approved prescribing information claims that “82% of patients reported an improvement in their erections.” Other PDE-5 inhibitors (Cialis, Levitra) showed similar results.</p>
                                                        <br>
                                                        <p>Oral ED medication is generally considered extremely effective and studies show it works on the majority of men. But ED medication doesn’t work for everyone. It’s important to note that everyone’s reaction to a medication is unique.</p>
                                                        <br>
                                                        <p>Your Better-Doctor physician may recommend trying the medication multiple times under different circumstances. They may also increase your dose, depending on your reaction to the medication and any side effects. If you’ve been prescribed ED medication on the Better-Doctor platform, please contact a physician on from your account page if you have any questions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse34" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can a doctor diagnose erectile dysfunction remotely?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse34" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>Yes, in most cases. Erectile dysfunction can be diagnosed by a physician based on a patient self-reporting their symptoms and medical history.</p>
                                                        <br>
                                                        <p>Healthcare professionals on the Better-Doctor platform use telemedicine technologies to diagnose ED in the same way they diagnose patients in-person. Doctors or nurse practitioners ask you questions to evaluate your symptoms and make sure it’s safe and appropriate to prescribe ED medication. The physicians on the Better-Doctor platform use your answers to diagnose your condition and create a treatment plan. That’s why it’s vital you answer each question to the best of your knowledge and ensure that every communication with your physician is truthful, accurate, and thorough.</p>
                                                        <br>
                                                        <p>In addition, it’s strongly recommended that you let your physician order laboratory tests to assist in trying to understanding the cause of your ED. These tests are optional and not a requirement for receiving treatment, but more information is always useful for diagnosis and treatment.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse35" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Why do I need to know my blood pressure to get an ED prescription?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse35" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>Your blood pressure is an important part of determining whether it’s safe for you to take ED medication. It can be dangerous to take ED medication if your blood pressure is above or below a certain value.</p>
                                                        <br>
                                                        <p>To complete a comprehensive medical history during your online visit, you need to enter your most recent blood pressure reading within the last 6 months. If you haven’t had your blood pressure taken within the last 6 months, you can get a free blood pressure reading at thousands of locations across the country. Enter your location into higi or Pursuant Health to find a place near you that offers free, clinically verified blood pressure readings.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse36" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How do I take my blood pressure at home?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse36" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>If you’re measuring your blood pressure at home, make sure the device has been “clinically validated.” You can purchase a device online or at your local pharmacy. We recommend an upper arm cuff over a wrist or finger blood pressure monitor.</p>
                                                        <br>
                                                        <p>Before taking your blood pressure, it’s important to avoid the following factors that may cause your blood pressure to temporarily rise:</p>
                                                        <ul>
                                                            <li>Cold temperatures</li>
                                                            <li>Exercise</li>
                                                            <li>Caffeine</li>
                                                            <li>Stress</li>
                                                            <li>Smoking</li>
                                                            <li>Certain medications (check the label on your device)</li>
                                                        </ul>
                                                        <p>When you’re ready, find a quiet place and sit for 5-10 minutes. Make sure you’re relaxed and have recently urinated (a full bladder can affect your reading). Roll up your sleeve so that your arm is bare. Apply the cuff on your upper arm and following the directions on the device.</p>
                                                        <br>
                                                        <p>Please make sure to take at least two readings. Enter the higher of the two readings during your online dynamic visit.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse37" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do you offer alternative treatments for erectile dysfunction?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse37" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>Oral ED medications are extremely effective, but they don’t work for everyone.</p>
                                                        <br>
                                                        <p>Other treatment options for ED include lifestyle improvements (like exercise, diet, quitting smoking, and limiting alcohol use), surgery, penile vacuum pumps, and other ED medications. Every treatment comes with risks and benefits that you should fully understand before moving forward with any treatment plan.</p>
                                                        <br>
                                                        <p>Currently, the only erectile dysfunction treatment we offer are oral PDE-5 inhibitors in the form of:</p>
                                                        <ul>
                                                            <li>Sildenafil (Viagra)</li>
                                                            <li>Tadalafil (Cialis)</li>
                                                        </ul>
                                                        <p>If you’d like to pursue alternative ED treatment, we recommend speaking with a physician in-person. Contact our team at
                                                            <a href="mailto:support@getbetterdoctor.com">support@getbetterdoctor.com </a> and we can recommend a doctor in your area.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse38" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does my order ship?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse38" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>Orders ship in discreet boxes, with all medication in single use packs. These single use packs are not child proof. Child proof packaging is available upon request by emailing
                                                            <a href="mailto:support@getbetterdoctor.com">support@getbetterdoctor.com </a> </p>
                                                        <br>
                                                        <p>Orders are shipped 2 Day Air to ensure medication is received as soon as possible. Postal Boxes and Mail Forwarding addresses will receive standard postal shipping and not 2 Day Air at this time.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse39" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Where can I get my blood pressure measured for free?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse39" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>Enter your location into higi or Pursuant Health to find the nearest location to get a free, clinically verified blood pressure reading. Higi Stations are available free of charge at Rite Aids nationwide, and Pursuant Health stations are available free of charge at Walmarts nationwide.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse40" data-parent="#accordion-tab-2" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What type of ED medication do you prescribe?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse40" class="collapse" aria-expanded="false" data-parent="#accordion-tab-2" style="">
                                                    <div class="card-body">
                                                        <p>According to the American Urological Association, oral PDE-5 inhibitors should be offered as the “first line of therapy for erectile dysfunction.” So that’s what physicians on the Better-Doctor platform use to treat ED. The most common PDE-5 oral medications they prescribe are:</p>
                                                        <ul>
                                                            <li>Sildenafil (Viagra)</li>
                                                            <li>Sildenafil (Sildenafil)</li>
                                                            <li>Tadalafil (Cialis)</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab3" role="tabpanel" aria-labelledby="tab3">
                                        <div class="faq-head">
                                            <h3>Premature ejaculation</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-3">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse41" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does P.E. treatment work with Better-Doctor?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse41" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>We use telemedicine technology and U.S. licensed healthcare professionals to provide premature ejaculation treatments discreetly, conveniently, and inexpensively.</p>
                                                        <br>
                                                        <p>It starts with your online visit. Your doctor or nurse practitioner needs to know about your health (e.g., your medications, lifestyle issues, prior surgeries) and how P.E. affects you. They also need to see a personal ID to confirm your identity and know who they will be helping. They review everything, determine if you’re a candidate for telemedicine and, if so (most people are), they will craft a personalized treatment plan.</p>
                                                        <br>
                                                        <p>Their treatment plan will include a great deal of information about P.E. It is important you take the time to read it all. You will be better prepared to manage your condition as a partner—and not just a patient.</p>
                                                        <br>
                                                        <p>If you qualify, our doctors or nurse practitioners will provide a diagnosis, behavioral therapy tips and prescribe the right course of treatment to help you last longer during sex.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse42" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What is P.E.?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse42" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>One of the problems with the diagnosis and treatment of PE is that until recently there has not been agreement on what constitutes premature ejaculation. Many men believe they orgasm more quickly than they should only to discover that their time to orgasm is well within the average range. Other men might orgasm in 2 minutes but find it is neither a cause for concern for them nor for their partner.</p>
                                                        <br>
                                                        <ul>
                                                            <li>According to the International Society of Sexual Medicine (ISSM) premature ejaculation is a male sexual dysfunction characterized by the following: Ejaculation that always or nearly always occurs prior to or within about 1 minute of vaginal penetration from the first sexual experience—Lifelong Premature Ejaculation (LPE), OR a clinically significant reduction in latency time (time it takes to reach orgasm after engaging in sex), often to about 3 minutes or less–Acquired Premature Ejaculation (APE);</li>
                                                            <li>The inability to delay ejaculation on all or nearly all vaginal penetrations; and</li>
                                                            <li>Negative personal consequences, such as distress, bother, frustration, and/or the avoidance of sexual intimacy.</li>
                                                        </ul>
                                                        <p>All three elements must be included for PE to be diagnosed. Men with PE generally fall into two groups: those who have it from their first sexual experience and those who develop it later. The good thing is that many of the interventions work for both groups.</p>
                                                        <br>
                                                        <p>Another definition of PE that has been suggested by the World Health Organization (WHO) is “persistent or recurrent ejaculation with minimal stimulation before, on, or shortly after penetration and before the person wishes it, over which the sufferer has little or no voluntary control which causes the sufferer and/or his partner bother or distress.” This second definition is more flexible. It is also inclusive of gay men and their partners, which is a welcome addition to the definition.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse43" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is P.E. medication effective?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse43" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>Although no single treatment works for everyone, most men can be treated successfully. Just be sure to stay in touch with your physician. There are multiple medications that can be tried, different topical therapies, and varied techniques that can be used alone or in unique combinations. Some people see immediate results. Others will find they need to try a few different methods before finding the right solution. If things don’t go as planned or if you experience an adverse side effect, don’t give up. You have options and we are here to help you find the best solution.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse44" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What type of PE medication do Better-Doctor affiliated physicians prescribe?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse44" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>In addition to behavioral tips, there are several medication options that your doctor or nurse practitioner may recommend, depending on your unique health situation.These include the most effective treatments recommended by the American Urological Association.</p>
                                                        <br>
                                                        <p>The simplest effective treatment is usually the best choice, so your provider will try to prescribe the medication that is most likely to work well for you. Based on your feedback, however, your management may change over time by switching to another medication choice or using a combination of agents. Some men use an oral medication and apply a topical anesthetic that they then cover with a condom. They get the sensations they want, experience the prolonged pleasure of an extended sexual experience, and orgasm at a time both they and a partner find satisfying. For other men, such a combination is overkill. That is why your personal experience is so important.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse45" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What is off-label prescribing?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse45" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>The FDA requires that drugs go through a clinical testing process to establish that they’re safe and effective to treat a particular condition. Healthcare providers generally may prescribe the drug for an unapproved use when they judge that it is medically appropriate for their patient. This type of prescribing is called “off-label” prescribing. Off-label prescribing practices are sometimes validated by clinical experience and research and may be a widely recognized clinical practice. Better-Doctor affiliated providers have the discretion to prescribe certain drugs off-label based a patient’s unique medical history, symptoms, and preferences.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse46" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Why can P.E. and Erectile Dysfunction (E.D.) be treated with the same medication?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse46" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>What is most interesting is that ED often is an underlying cause of PE. Sometimes, the same stress that causes either one can cause both. However, ED itself can begin a cascade of changes that results in PE. This is because men with ED often attempt to keep themselves erect by vigorously stimulating themselves, and this intense stimulation can inadvertently result in PE. When the erection is supported by sildenafil or tadalafil, vigorous stimulation is often not needed to maintain an erection. With less vigorous stimulation, men gain more control over the timing of their ejaculation.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse47" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Are there side effects of P.E. medication?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse47" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>The medications available for treating P.E. work for the vast majority of patients and they usually do so without causing side effects. However, just as no drug works on everyone, no drug is 100% safe, and these treatments are no exception. Becoming familiar with the possible side effects will help you identify if you are one of the men who experience them. If you experience an adverse side effect, don’t give up. You have options and we are here to help you find the best solution.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse48" data-parent="#accordion-tab-3" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are the non-prescription options for treating P.E.?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse48" class="collapse" aria-expanded="false" data-parent="#accordion-tab-3" style="">
                                                    <div class="card-body">
                                                        <p>Your physician may recommend a combination of medication and behavioral therapy tips to deal with P.E. No two people are alike, and it may take some time and experimentation to find the methods that work best for you. Some men experience significant improvement with learned techniques so medication isn’t for everyone.
                                                            <br>
                                                        <p>Some recommended techniques include:</p>
                                                        <br>
                                                        <p>
                                                            <span style="font-weight: 600;">Anesthetics: Topical anesthetics can be applied to the penis just before sexual activity. They come as creams, lotions, or sprays and are available over-the-counter. The advantage of topical applications is that the side effect rate is low and they are effective to some degree in almost everyone to decreasing sensation and prolonging the time before ejaculation. </span>
                                                        </p>
                                                        <br>
                                                        <p>
                                                            <span style="font-weight: 600;">Condoms: Condoms can help with PE by decreasing penile sensitivity and have the added benefit of reducing the likelihood of transmission of sexually transmitted infection or unwanted pregnancy. Using a spray, cream, or lotion with a condom is surprisingly effective for some men. It delays orgasm, gives some men just the right amount of pleasurable sensation, and when used with a topical anesthetic prevents the spread of the anesthetic to a partner. </span></p>
                                                        <br>
                                                        <p>
                                                            <span style="font-weight: 600;">Behavioral modification: The squeeze and start-stop techniques are 2 different strategies that can be practiced during masturbation or sexual activity and, over time, can improve control over the timing of ejaculation. </span></p>
                                                        <br>
                                                        <p>
                                                            <span style="font-weight: 600;">Psychotherapy: Counseling, whether in person, by telephone, or by text messaging, may increase the likelihood of success for some patients. The reality is that men who have had lifelong issues have often suffered silently with a burden that weighs on them every time they consider developing a physical and emotional attachment to another person. They face disappointing the very person they hope to please the most. For many men, it terminates relationships before they begin and can lead to isolation and a loss of companionship. Men with PE face increased risks of divorce and relationship stress, often more as a result of the emotional consequences of having PE than from the PE itself. Uncovering the reasons the PE developed are as important as breaking the cycle with medical interventions. </span></p>
                                                        <br>
                                                        <p>Also, psychotherapy can be enormously helpful in restoring a man’s sense of value and worth beyond the ability to delay an orgasm, which often restores their capacity to experience sex as they had before PE developed.</p>
                                                        <br>
                                                        <p>
                                                            <span style="font-weight: 600;">Combination Therapy: Any of the above treatments can be combined with any one or more of the other treatments. Although it’s often best to keep things simple, the good news is that combination therapy can help treat men who don’t respond satisfactorily to single mode therapies. </span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab4" role="tabpanel" aria-labelledby="tab4">
                                        <div class="faq-head">
                                            <h3>Hair loss</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-4">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse51" data-parent="#accordion-tab-4" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does hair loss treatment with Better-Doctor work?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse51" class="collapse" aria-expanded="false" data-parent="#accordion-tab-4" style="">
                                                    <div class="card-body">
                                                        <p>We use telemedicine technology and U.S. licensed physicians  to provide hair loss treatment that’s reliable, convenient, and discreet.
                                                            <br>
                                                        <p>It starts with your dynamic online visit. Your Better-Doctor affiliated healthcare professional needs to know about your health (current prescriptions, lifestyle, habits, medical history, and symptoms) and see pictures of your current hair loss. They also need to see an official ID to confirm your identity. They review everything, determine if telemedicine is safe for you , and either approve your treatment request or follow up within 24 hours.</p>
                                                        <br>
                                                        <p>If you qualify, our doctors and nurse practitioners will provide a diagnosis and prescribe the right course of treatment to help you stop hair loss—and you may even regrow hair.</p>
                                                        <br>
                                                        <p>If approved, your FDA-approved medication will come with a  treatment plan with a great deal of information about hair loss. It is important you take the time to read it all.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse52" data-parent="#accordion-tab-4" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What type of hair loss treatment do you prescribe?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse52" class="collapse" aria-expanded="false" data-parent="#accordion-tab-4" style="">
                                                    <div class="card-body">
                                                        <p>Our physicians may prescribe finasteride (generic Propecia) to treat male pattern hair loss (androgenic alopecia). This medication helps patients maintain the hair they have or possibly even regrow some hair they have lost.</p>
                                                        <br>
                                                        <p>You can also get minoxidil (generic Rogaine), an over-the-counter topical treatment, to slow or even stops hair loss at the crown of the head.</p>
                                                        <br>
                                                        <p>Your doctor or nurse practitioner may recommend the use of finasteride combined with minoxidil.</p>
                                                        <br>
                                                        <p>Finasteride is not appropriate for all men and should never be used by women or handled by pregnant women.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse53" data-parent="#accordion-tab-4" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is hair loss treatment effective?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse53" class="collapse" aria-expanded="false" data-parent="#accordion-tab-4" style="">
                                                    <div class="card-body">
                                                        <p>Hair loss treatment can work to preserve hair and possibly even regrow hair. In one study, Finasteride was effective at stopping hair loss in 83% of men compared to 28% for the placebo group.</p>
                                                        <br>
                                                        <p>Finasteride is not appropriate for all men and should never be used by women or handled by pregnant women.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse54" data-parent="#accordion-tab-4" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How long does it take for hair loss treatment to work?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse54" class="collapse" aria-expanded="false" data-parent="#accordion-tab-4" style="">
                                                    <div class="card-body">
                                                        <p>Finasteride and minoxidil typically take 3 to 4 months of daily use to start seeing visible changes. It can take up to a year to see the maximum results. As long as you don’t experience side effects, it’s best to continue medication for one full year before deciding on whether the medication is doing all it can for you. Like all medications, finasteride and minoxidil will work differently for different people.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse55" data-parent="#accordion-tab-4" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Are there side effects or hair loss medication?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse55" class="collapse" aria-expanded="false" data-parent="#accordion-tab-4" style="">
                                                    <div class="card-body">
                                                        <p>The most common side effects associated with minoxidil are local skin irritation. Systemic side effects are rare as less than 2% of it gets absorbed.</p>
                                                        <br>
                                                        <p>Like most medications, finasteride is not safe for everyone. In clinical trials, less than 2% of men experienced sexual dysfunction due to Finasteride. In addition, finasteride can lower serum prostate-specific antigen and may increase the risk of high-grade prostate cancers. If you decide to seek care through Better-Doctor , your Better-Doctor ffiliated physician will be available to answer your questions about side effects. Finasteride is not approved for use in women, and pregnant women should not handle finasteride.</p>
                                                        <br>
                                                        <p>Just as no drug works on everyone, no drug is 100% safe, and finasteride is no exception. Becoming familiar with the possible side effects will help you identify if you are one of the men who experiences them. If you do, you should stop immediately and inform your doctor.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse56" data-parent="#accordion-tab-4" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need to take a photo of my hair?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse56" class="collapse" aria-expanded="false" data-parent="#accordion-tab-4" style="">
                                                    <div class="card-body">
                                                        <p>Yes. During your online visit, we’ll ask you to take and upload a few photos. This helps your physician determine the appropriate treatment option.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab5" role="tabpanel" aria-labelledby="tab5">
                                        <div class="faq-head">
                                            <h3>Cold sores</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-5">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse61" data-parent="#accordion-tab-5" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does cold sore (oral herpes) treatment with Better-Doctor work?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse61" class="collapse" aria-expanded="false" data-parent="#accordion-tab-5" style="">
                                                    <div class="card-body">
                                                        <p>We use telemedicine technology and U.S. licensed healthcare professionals to provide cold sore treatments discreetly, conveniently, and inexpensively.</p>
                                                        <br>
                                                        <p>It starts with your online visit. Your doctor or nurse practitioner needs to know about your health (e.g., your medications, lifestyle issues, prior surgeries) and how cold sores affect you.</p>
                                                        <br>
                                                        <p>They also need personal ID so they know who they will be helping in the coming year. They review everything, determine if you’re a candidate for telemedicine and, if so (most people are), they will craft a personalized treatment plan.</p>
                                                        <br>
                                                        <p>Their treatment plan will include a great deal of information about oral herpes. It is important you take the time to read it all. You will be better prepared to manage your condition as a partner—and not just a patient.</p>
                                                        <br>
                                                        <p>If you qualify, our doctors or nurse practitioners will provide prescribe the right course of treatment to help you take control of cold sore outbreaks.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse62" data-parent="#accordion-tab-5" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What type of cold sore medication do you prescribe?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse62" class="collapse" aria-expanded="false" data-parent="#accordion-tab-5" style="">
                                                    <div class="card-body">
                                                        <p>Our Physicians prescribe Valacyclovir (generic Valtrex) to treat cold sores (oral herpes). When used properly, this medication can abort an outbreak at the first sign that one is coming on or lessen its severity.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse63" data-parent="#accordion-tab-5" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is cold sore medication effective?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse63" class="collapse" aria-expanded="false" data-parent="#accordion-tab-5" style="">
                                                    <div class="card-body">
                                                        <p>Cold sore (oral herpes) medication is available, and it works extremely well. But it requires you to dedicate the time to learn about your condition and work with your physician to craft the ideal, personalized treatment plan.</p>
                                                        <br>
                                                        <p>When you do, you’ll be able to identify your specific symptoms (prodrome) and use your medication to shorten or stop the outbreak from occurring.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse64" data-parent="#accordion-tab-5" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are the side effects of cold sore medication?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse64" class="collapse" aria-expanded="false" data-parent="#accordion-tab-5" style="">
                                                    <div class="card-body">
                                                        <p>What follows is a summary and does not include every side effect possible.</p>
                                                        <br>
                                                        <p>Common side effects include headache, feeling sick, dizziness, vomiting, diarrhea, skin reaction after exposure to sunlight (photosensitivity), rash, or itching (pruritus).</p>
                                                        <br>
                                                        <p>Uncommon side effects include feeling confused, seeing or hearing things that aren’t there (hallucinations), feeling very drowsy, tremors, or feeling agitated, shortness of breath (dyspnea), stomach discomfort, rash, sometimes itchy, hive-like rash (urticaria), low back pain (kidney pain), blood in the urine (hematuria), reduction in the number of blood platelets which are cells that help blood to clot (thrombocytopenia), reduction in the number of white blood cells (leucopenia), increase in substances produced by the liver.</p>
                                                        <br>
                                                        <p>Rare side effects include unsteadiness when walking and lack of coordination (ataxia), slow, slurred speech (dysarthria), fits (convulsions), altered brain function (encephalopathy), unconsciousness (coma), confused or disturbed thoughts (delirium), kidney problems where you pass little or no urine. Lastly, watch out for a severe allergic reaction (anaphylaxis). These are rare in people taking Valtrex. Anaphylaxis is marked by the rapid development of flushing, itchy skin rash, swelling of the lips, face, neck, and throat—causing difficulty in breathing (angioedema), fall in blood pressure leading to collapse. If any of these occur, get emergency treatment immediately</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse65" data-parent="#accordion-tab-5" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can a doctor treat cold sores remotely?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse65" class="collapse" aria-expanded="false" data-parent="#accordion-tab-5" style="">
                                                    <div class="card-body">
                                                        <p>Yes. Our doctors and nurse practitioners can prescribe medication and help you dial in the most effective treatment plan for your unique needs.</p>
                                                        <br>
                                                        <p>Healthcare professionals on the Ro platform use telemedicine technologies to treat cold sores in the same way they treat patients in-person. Doctors or nurse practitioners ask you questions to evaluate your symptoms and make sure it’s safe and appropriate to prescribe cold sore medication. The healthcare professionals on the Better-Doctor platform use your answers to assess your condition and create a treatment plan. That’s why it’s vital you answer each question to the best of your knowledge and ensure that every communication with your physician is truthful, accurate, and thorough.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse66" data-parent="#accordion-tab-5" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need to take a photo of my cold sore outbreak?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse66" class="collapse" aria-expanded="false" data-parent="#accordion-tab-5" style="">
                                                    <div class="card-body">
                                                        <p>No</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse67" data-parent="#accordion-tab-5" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Why is the medication for oral and genital herpes the same?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse67" class="collapse" aria-expanded="false" data-parent="#accordion-tab-5" style="">
                                                    <div class="card-body">
                                                        <p>Though they infect different parts of the body, cold sores (oral herpes) and genital herpes are caused by the same virus. There are two strains of the herpes virus; cold sores are more frequently caused by HSV-1, while genital herpes is more commonly associated with HSV-2. Both strains of the virus can be effectively treated using the same medication.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab6" role="tabpanel" aria-labelledby="tab6">
                                        <div class="faq-head">
                                            <h3>Allergies</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-6">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse71" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What is allergic rhinitis?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse71" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Allergic rhinitis is sometimes called “hay fever” or “seasonal allergies,” although it can be caused by nonseasonal substances as well. Allergic rhinitis refers to inflammation in the nose (rhinitis) that occurs when you are exposed to a substance you are allergic to. The term “allergic rhinitis” does not refer to all kinds of allergies you may have. For example, food allergies and allergies to medications do not typically cause allergic rhinitis.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse72" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are allergies?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse72" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>“Allergies” is a general term that refers to a reaction the body has to allergens. Allergens are substances that are generally not harmful to the body. However, if you have allergies, the immune system thinks allergens are dangerous and tries to fight them off like it would any other intruder, such as bacteria, viruses, and parasites. Having allergies, therefore, means the immune system is overactive. In other words, the immune system is doing something it is normally supposed to do but the reaction is exaggerated, unnecessary, and even harmful to the body. In medical terminology, this overreaction is called a type 1 hypersensitivity reaction.</p>
                                                        <br>
                                                        <p>There are many different kinds of allergies that differ based on the part of the body affected and the severity of symptoms. In some cases, allergies may cause a small rash or runny nose. In other cases, allergies can be life-threatening. Types of allergies include allergic rhinitis (inflammation in the nose), contact dermatitis (an allergic reaction on the skin), allergic asthma (a specific type of asthma), and anaphylaxis (a life-threatening condition that involves swelling of the airways).</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse73" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are the most common allergens?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse73" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>There are many different allergens that affect the body. These include foods, plants, animals, metals, chemicals, medications, and more. Some allergens are known as airborne allergens because they are present in the air. The most common airborne allergens include tree pollens, grass pollens, weed pollens, pet dander, and molds. Dust mites and cockroach allergens are also common and may be airborne, however they settle more quickly than the other airborne allergens.</p>
                                                        <br>
                                                        <p>Better-Doctor only offers treatment for allergic rhinitis, which is caused by airborne allergens. However, there are many other common allergens you may have. The most common food allergens are fish, shellfish, peanuts, tree nuts, eggs, wheat, soy, and milk. These allergens always need to be labeled on food if they are present. Other allergens you may have heard of include:</p>
                                                        <ul>
                                                            <li>Insect venom (e.g. getting stung by a bee)</li>
                                                            <li>Nickel (e.g. getting a rash after wearing certain jewelry)</li>
                                                            <li>Latex (e.g. getting a rash when coming into contact with latex)</li>
                                                            <li>Medications (e.g. getting hives after taking penicillin)</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse74" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What causes allergic rhinitis?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse74" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Allergic rhinitis is typically caused by allergens that are airborne. These are roughly divided into outdoor airborne allergens and indoor airborne allergens. Common airborne allergens found outdoors include tree pollens, grass pollens, and weed pollens. Common airborne allergens found indoors include animal dander, dust mites, and mold.</p>
                                                        <br>
                                                        <p>Outdoor allergens are usually seasonal because plants release pollen at certain times of the year. Spring allergies are commonly due to tree pollens. Late spring and summer allergies are commonly due to grass pollens. Late summer and autumn allergies are commonly due to weed pollens, such as ragweed pollen. In fact, ragweed pollen is one of the primary causes of allergic rhinitis and 75% of people with pollen allergies are allergic to ragweed.</p>
                                                        <br>
                                                        <p>Indoor allergens may be present throughout the year. However, certain situations can make indoor allergies worse. For example, animal dander allergies may be worse in the winter because pets may spend more time indoors and there is less circulating fresh air. And mold allergies may be worse if there is a very rainy spring season.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse75" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What causes allergies?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse75" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Researchers aren’t sure why some people develop allergies and others do not. What we do know is that the tendency to develop allergies is genetic. This means if your family members have allergies, you are more likely to have them as well. Additionally, certain allergic conditions commonly occur together. Known as the “atopic triad,” these conditions are allergic rhinitis, eczema (also called atopic dermatitis), and allergic asthma.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse76" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What is immunoglobulin E (IgE)?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse76" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Immunoglobulin E (IgE) is a type of antibody. Antibodies are proteins that the body forms to help fight invaders, known as pathogens. There are several kinds of antibodies that the body can make. IgA antibodies are typically found in mucous membranes, such as the mouth and the intestinal linings. IgA is also found in breastmilk and can transfer from mother to baby. IgD antibodies are less understood and signal certain immune cells to activate. IgM antibodies are the first antibodies the body makes when exposed to a pathogen. And IgG antibodies take longer to make but are the antibodies we typically think of that are associated with immunity to diseases.</p>
                                                        <br>
                                                        <p>IgE antibodies are normally beneficial because they help protect against parasites. However, IgE antibodies are also responsible for allergic reactions. When the body encounters an allergen for the first time, it develops IgE antibodies that are targeted against that allergen. The IgE antibodies then attach to the surface of immune cells called mast cells and basophils. The next time the body is exposed to the same allergen, the allergen links up with the IgE antibodies, beginning a series of events that result in allergy symptoms.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse77" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What is Histamine?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse77" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Histamine is an inflammatory chemical that is released by immune cells during an allergic reaction. The release of histamine is normally helpful because it can help fight pathogens that are affecting the body. However, during an allergic reaction, histamine is released when it shouldn’t be. Histamine is responsible for symptoms like watery eyes, runny nose, sneezing, and itchy skin.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse78" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are Leukotrienes?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse78" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Leukotrienes are inflammatory chemicals that are released by immune cells during an allergic reaction. The release of leukotrienes is normally helpful because they can help fight pathogens that are affecting the body. However, during an allergic reaction, leukotrienes are released when they shouldn’t be. Leukotrienes are responsible for inflammation and the production of a large amount of mucus.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse79" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are the steps of an allergic reaction?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse79" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Vitamin D is necessary for the normal functioning of several bodily processes. One study on overweight men who had a vitamin D deficiency found that after a year of supplementation with vitamin D, testosterone levels increased. Another small study found that supplementation with vitamin D was associated with increased testosterone levels and improved erectile function. However, vitamin D has not been evaluated by the FDA as a treatment for low testosterone and further studies need to be done to better understand the effects and optimal dosing of vitamin D.</p>
                                                        <br>
                                                        <ul>
                                                            <li>The first time the body is exposed to an allergen, it responds by creating antibodies known as immunoglobulin E (IgE).</li>
                                                            <li>IgE antibodies attach to the surface of immune cells called mast cells and basophils.</li>
                                                            <li>The next time the body is exposed to the same allergen, the allergen links up with the IgE antibodies. This prompts the mast cells and basophils to release inflammatory chemicals, including histamine and leukotrienes.</li>
                                                            <li>The release of histamine and leukotrienes causes the symptoms that are typically associated with allergies. Histamine is responsible for watery eyes, runny nose, sneezing, and itchy skin. Leukotrienes are responsible for inflammation and the production of a large amount of mucus.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse80" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does azelastine help treat allergic rhinitis?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse80" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Azelastine works by blocking the H1 receptors on cells, which are receptors that are normally activated by the chemical histamine. When the body is exposed to an allergen, immune cells (called mast cells and basophils) release histamine. Since azelastine blocks histamine from activating the H1 receptors, the symptoms that are typically caused by histamine are lessened. Azelastine also has anti-inflammatory effects and reduces congestion.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse8" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does fluticasone propionate help treat allergic rhinitis?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse8" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Researchers are not entirely sure how fluticasone propionate works but it is known to have a wide range of effects on different immune cells and chemicals that cause inflammation, including mast cells, histamine, and leukotrienes.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse9" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does levocetirizine help treat allergic rhinitis?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse9" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Levocetirizine works by blocking the H1 receptors on cells, which are receptors that are normally activated by the chemical histamine. When the body is exposed to an allergen, immune cells (called mast cells and basophils) release histamine. Since levocetirizine blocks histamine from activating the H1 receptors, the symptoms that are typically caused by histamine are lessened.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse10" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does montelukast help treat allergic rhinitis?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse10" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Montelukast works by blocking cysteinyl leukotriene receptors, which can be activated by the chemical leukotriene D4. When the body is exposed to an allergen, immune cells (including mast cells and basophils) release leukotriene D4. Since montelukast blocks leukotriene D4 from activating the cysteinyl leukotriene receptors, the symptoms that are typically caused by leukotriene D4 are lessened.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse11" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Does azelastine make you drowsy?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse11" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Yes, azelastine may make you drowsy. Azelastine is a type of medication known as an antihistamine. One of the common side effects of antihistamines is drowsiness. As such, azelastine may make users drowsy after use. Users should exercise caution when taking azelastine and should be aware that mental alertness may be decreased. This can impact the ability to drive or operate machinery.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse12" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Does fluticasone propionate make you drowsy?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse12" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>No, fluticasone propionate should not make you drowsy. Antihistamines are a group of medications that are commonly used to treat allergies and one of the side effects of antihistamines is drowsiness. However, fluticasone propionate is not an antihistamine. It is a type of medication known as a glucocorticoid. Therefore, fluticasone propionate does not typically cause drowsiness as other allergy medications do. That being said, the Prescriber’s Digital Reference (PDR) for fluticasone propionate does list fatigue as one of the many possible side effects of taking the medication.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse13" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Does levocetirizine make you drowsy?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse13" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>It is possible levocetirizine will make users drowsy but the exact answer is unclear. Levocetirizine is a type of medication that is sometimes referred to as a third-generation antihistamine. It is derived from cetirizine (brand name Zyrtec), which is a second-generation antihistamine. The third-generation antihistamines are a new type of drug class that is believed to cause less drowsiness than the second-generation antihistamines. However, the exact extent of this difference is unclear. As such, while it is possible that levocetirizine is less sedating than cetirizine, it still may cause drowsiness. Users should exercise caution when taking levocetirizine and should be aware that mental alertness may be decreased. This can impact the ability to drive or operate machinery.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse14" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Does montelukast make you drowsy?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse14" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>No, montelukast should not make you drowsy. Antihistamines are a group of medications that are commonly used to treat allergies and one of the side effects of antihistamines is drowsiness. However, montelukast is not an antihistamine. It is a type of medication known as a leukotriene receptor antagonist. Therefore, montelukast does not typically cause drowsiness as other allergy medications do. That being said, the Prescriber’s Digital Reference (PDR) for montelukast does list fatigue as one of the many possible side effects of taking the medication.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse15" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What should you do if you’re experiencing a severe allergic reaction?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse15" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>A severe allergic reaction is known as anaphylaxis. Better-Doctor does not offer treatment for anaphylaxis. If you are experiencing a severe allergic reaction (or even if you just think you might be in trouble), call <a href="tel:911"> 911 </a> immediately and seek medical help. The symptoms of a severe allergic reaction include itching of the mouth and lips, swelling of the airway, tongue, and lips, flushing, hives, decreased blood pressure, difficulty breathing, confusion, and loss of consciousness. These symptoms may occur rapidly and are life-threatening, requiring emergent treatment.</p>
                                                        <br>
                                                        <p>If you have a history of anaphylaxis or are at risk of developing anaphylaxis, your healthcare provider may have prescribed you a medication called epinephrine. You should carry this medication with you at all times and should give it to yourself (or have somebody else give it to you) in the event of a severe allergic reaction without delay.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse16" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need a prescription for azelastine?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse16" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Yes, you need a prescription from a healthcare provider to obtain azelastine nasal spray. The medication comes in several doses and is normally available as the generic or as the brand name, Astelin. Ro only offers the generic form, which will be prescribed to you if your Better-Doctor affiliated physician believes it is appropriate for the treatment of your allergic rhinitis.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse17" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need a prescription for fluticasone propionate?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse17" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>No, you do not need a prescription from a healthcare provider to obtain fluticasone propionate nasal spray. This means you can get the medication either with a prescription or over-the-counter. The medication is normally available as the generic or as the brand name, Flonase. Ro only offers the generic form, which will be prescribed to you if your Better-Doctor affiliated physician believes it is appropriate for the treatment of your allergic rhinitis.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse18" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need a prescription for levocetirizine?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse18" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>No, you do not need a prescription from a healthcare provider to obtain levocetirizine. This means you can get the medication either with a prescription or over-the-counter. The medication is normally available as the generic or as the brand name, Xyzal. Ro only offers the generic form, which will be prescribed to you if your Better-Doctor affiliated physician believes it is appropriate for the treatment of your allergic rhinitis.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse19" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Do I need a prescription for montelukast?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse19" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Yes, you need a prescription from a healthcare provider to obtain montelukast. The medication comes in several doses and is normally available as the generic or as the brand name, Singulair. Ro only offers the generic form, which will be prescribed to you if your Better-Doctor affiliated physician believes it is appropriate for the treatment of your allergic rhinitis.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse20" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I take azelastine as-needed?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse20" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Yes, you can take azelastine as-needed for symptom relief. Azelastine is rapid-acting and typically starts working within 15 minutes. Taking azelastine as-needed will lead to a temporary improvement in sneezing and itchy and runny nose. However, the effects of the medication will wear off after 12 hours, so if you need continuous symptom relief it is recommended you take the medication twice daily. Using azelastine twice daily may also lead to an improvement in overall congestion.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse01" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I take fluticasone propionate as-needed?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse01" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Yes, you can take fluticasone propionate as-needed. However, if you do so, you may not experience the medication’s maximal effectiveness. Fluticasone propionate normally starts working after 12 hours but it may take 10 to 14 days of continuous, daily usage to see the most improvement in nasal congestion.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse02" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I take levocetirizine as-needed?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse02" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Yes, you can take levocetirizine as-needed for symptom relief. Levocetirizine is fast-acting and typically starts working after 1 hour. Taking levocetirizine as-needed will lead to a temporary improvement in sneezing, runny nose, and itchy eyes. However, the effects of the medication will wear off after 24 hours, so if you need continuous symptom relief it is recommended you take the medication daily.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse03" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I take montelukast as-needed?</span>
			                                                <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse03" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>No, it is not recommended that you take montelukast as-needed. Montelukast may start working after the first dose in some users. However, to experience maximal improvement in nasal congestion, it should be continued for several days.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse04" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is fluticasone propionate bad for me because it’s a steroid?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse04" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Fluticasone propionate is a type of medication known as a glucocorticoid. Glucocorticoids are corticosteroid hormones that are effective at reducing inflammation. Corticosteroid hormones are not the same thing as anabolic steroids, which are the steroids commonly associated with athletic enhancement.</p>
                                                        <br>
                                                        <p>Using fluticasone propionate as prescribed for the treatment of allergic rhinitis is generally not considered dangerous. However, chronic overuse may cause specific signs and symptoms. Using greater than what is recommended by your physician can lead to trauma and irritation of the lining of the inside of the nose, which can lead to nosebleeds. Long-term use of high levels of corticosteroid hormones can cause other symptoms such as thinning skin, changes in body fat distribution, blood sugar problems, bone loss, and increased acne or facial hair. If you are experiencing worrisome symptoms, contact a healthcare provider.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse05" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What else can I do to treat my allergic rhinitis?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse05" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Besides medication, there are many other things you can be doing to treat your allergic rhinitis. One of the most important things is called allergen avoidance. This involves successfully staying away from the things you are allergic to. Some tips for how to avoid allergens include:</p>
                                                        <br>
                                                        <ul>
                                                            <li>If you have pollen allergies, you may benefit from staying indoors during certain times of the year or avoiding freshly cut grass. You can also keep your windows closed at home and in your car to avoid airborne exposure.</li>
                                                            <li>If you have animal dander allergies, you may not want to own a pet or visit the homes of friends who have pets.</li>
                                                            <li>If you have dust mite allergies, you may want to purchase a hypoallergenic mattress cover and pillow covers for your bed. Weekly washing of your bed linens will also help decrease the level of dust mite exposure.</li>
                                                            <li>If you have mold allergies, you may benefit from staying out of places where mold commonly grows, such as basements. Molds thrive in warm, damp areas, which also includes showers and bathrooms. Keeping your bathroom well ventilated or dry (with a dehumidifier) can help keep mold levels to a minimum.</li>
                                                        </ul>
                                                        <p>Purchasing a High Efficiency Particulate Air (HEPA) filter can also help protect against airborne allergens.</p>
                                                        <br>
                                                        <p>Another way to improve nasal symptoms is to perform nasal saline irrigation.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse06" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What is nasal saline irrigation?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse06" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Nasal saline irrigation is a practice that involves flushing the nasal passages with a large volume of a saline solution. It is highly recommended that everybody with airborne allergies irrigates their nose at least once a day (but ideally twice) per day. If you have airborne allergies, irrigating once before going outside and once when you come home is extremely helpful to clear the nose of the allergen particles that are causing your symptoms. Numerous companies sell devices that can be used for nasal saline irrigation, such as the neti pot or the Neil Med irrigation bottle.</p>
                                                        <br>
                                                        <p>Nasal saline irrigation can cause an unusual sensation that might be uncomfortable at first. It should always be performed with a warmed solution that is intended for nasal irrigation and not with fresh water. If you are mixing your own solution, it is extremely important that you do NOT use tap water, as this could be contaminated with organisms. Instead, make sure you only use water that has been distilled, sterilized, or boiled. If you use boiled water, do not mix the solution or use the water until it has cooled down to room temperature.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse07" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I take over-the-counter medications along with my treatment?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse07" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>There are many other oral and nasal medications that can be taken for allergic rhinitis. There are also eye drops that can be used to treat itchy eyes. Some of these other medications include other antihistamines, other corticosteroids, decongestants, cromolyn sodium, and ipratropium bromide.</p>
                                                        <br>
                                                        <p>While it is safe to combine some allergy medications, certain combinations may be dangerous or may cause excessive drowsiness. Therefore, it is always important to talk to your healthcare provider about what you are taking, including over-the-counter medications. Do not start any new medications in addition to the treatment you have been prescribed here without first talking to a healthcare provider.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse08" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Will my allergies ever go away?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse08" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>It is hard to say whether your allergies will ever go away. Certain allergies tend to be more common in children and many patients may find that their allergy symptoms improve with age. However, it is also possible to develop new allergies as an adult. You may also become more aware of certain allergies if you change your surroundings, such as if you move to a new city and are exposed to new pollens.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse09" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are allergy shots?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse09" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Allergy shots, also called immunotherapy, are a type of long-term treatment for allergies. Allergy shots are given on a weekly basis for several months. It may take up to a year to see any improvement in symptoms. Allergy shots can then be given every few weeks for approximately 3 to 5 years.</p>
                                                        <br>
                                                        <p>Prior to beginning allergy shots, an individual is tested to determine his or her specific allergens. The individual is then injected with small and increasing amounts of those allergens. By injecting the individual with allergens, his or her immune system is slowly retrained, becoming desensitized to those allergens. This means that when the individual is exposed to the allergens in the real world, he or she mounts less of a reaction to them. Allergy shots can potentially trigger anaphylaxis, so they must always be given in a healthcare provider’s office under supervision.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse00" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are allergy drops?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse00" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Allergy drops are also known as sublingual immunotherapy. The idea behind allergy drops is the same as allergy shots and involves gradual desensitization to certain allergens. Allergy drops are placed under the tongue daily and do not require administration in a healthcare provider’s office. However, there are not many allergens that are currently FDA-approved for allergy drops. Additionally, since allergy drops are newer, their long-term efficacy is unknown.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse001" data-parent="#accordion-tab-6" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Does alternative medicine work for allergies?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse001" class="collapse" aria-expanded="false" data-parent="#accordion-tab-6" style="">
                                                    <div class="card-body">
                                                        <p>Remedies that have shown some efficacy in treating allergic rhinitis include the following:</p>
                                                        <ul>
                                                            <li>Acupuncture and acupressure</li>
                                                            <li>Ayurvedic medicine, including a mixture of seven Indian herbs (Albizia lebbeck, Terminalia chebula, T. bellerica, Phyllanthus emblica, Piper nigrum, P. longum, and Zingiber officinale)</li>
                                                            <li>Herbal therapies, including butterbur, Tinospora cordifolia, a mixture of cinnamon bark, Spanish needle, and acerola, Benifuuki green tea, Yupingfeng granules, ginseng, and Tonggyu-tang</li>
                                                            <li>Topical treatments, including capsaicin, cellulose powder, and a petrolatum-based allergen-absorbing ointmentTopical treatments, including capsaicin, cellulose powder, and a petrolatum-based allergen-absorbing ointment</li>
                                                        </ul>
                                                        <p>Remedies with less strong evidence include the following:</p>
                                                        <ul>
                                                            <li>Herbal therapies, including quercetin, stinging nettle, Perilla frutescens, steamed eucalyptus, menthol, Ginkgo biloba, milk thistle, and grape seed extract</li>
                                                            <li>Laser therapy</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab7" role="tabpanel" aria-labelledby="tab7">
                                        <div class="faq-head">
                                            <h3>Eczema</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-7">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse81" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does eczema treatment with Better-Doctor work?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse81" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>We use telemedicine technology to connect you with a  U.S. licensed healthcare professional who can provide eczema treatment conveniently and inexpensively.</p>
                                                        <br>
                                                        <p>It starts with your online visit. Your doctor or nurse practitioner needs to know about your general health and how eczema affects you.</p>
                                                        <p>They also need an unedited photo of you and of your ID (that shows your face and birthdate) so they know who they will be helping, as well as a photo of the affected area(s) to confirm the diagnosis. They review everything, determine if you’re a candidate for telemedicine and whether treatment is right for you, and if so , they will send you a personalized treatment plan.</p>
                                                        <br>
                                                        <p>The treatment plan will include a great deal of information about eczema. It is important that you take the time to read it all to be informed and better prepared to manage your condition.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse82" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What medication do you prescribe for eczema?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse82" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>If a prescription is appropriate, Better-Doctor affiliated physicians may prescribe triamcinolone (0.1%) cream, a medium potency steroid cream. Steroid cream is effective for treating flare-ups and in certain cases may also be used to help prevent flare-ups, and is the recommended first-line treatment for eczema by the American Academy of Dermatology. Triamcinolone (0.1%) cream may not be appropriate for everyone.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse83" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I get treatment if I haven’t been diagnosed with eczema by a doctor before?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse83" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>At this time, we are only offering treatment for eczema if you have previously been diagnosed with eczema by a doctor or other healthcare provider.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse84" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can a doctor diagnose eczema remotely?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse84" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>Skin conditions may sometimes be difficult to definitively diagnose by telemedicine. At this time, we are only offering treatment for eczema if you have previously been diagnosed with eczema by a doctor or other healthcare provider.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse85" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I use the eczema treatment even if I’m not having a flare-up right now?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse85" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>Yes, triamcinolone cream may be used for prevention of flare-ups, if directed by your doctor. Long term steroid use may be associated with side effects, so it’s important to keep your doctor or nurse practitioner in the loop about the severity of your flare-ups so they can recommend the best treatment for you.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse86" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Is eczema treatment effective?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse86" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>Steroid creams, including triamcinolone, are considered effective for both treatment of active eczema as well as prevention of flare-ups, and are recommended by the American Academy of Dermatology as first-line treatment based on large volumes of high quality studies*. Triamcinolone (0.1%) cream may not be appropriate for everyone. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse87" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Can I use the eczema treatment in place of my regular moisturizer?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse87" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>Triamcinolone cream doesn’t contain a significant amount of moisturizer, so you should use a regular moisturizer in addition to triamcinolone cream. In general, it is important to moisturize your skin, especially in areas prone to flare-ups, as dry skin can make your eczema symptoms worse.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse88" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What are the side effects of eczema treatment?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse88" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>Triamcinolone cream can cause redness, dryness, itching, and/or irritation at the sites where it’s applied. These effects are usually mild, and can be helped by making sure the skin is properly moisturized. More serious side effects that can happen with prolonged use or if large amounts are used over large surface areas include thinning skin, stretch marks, or impaired wound healing. These can be avoided by only using the smallest amount of cream necessary for the shortest duration of time necessary (usually two weeks) to reduce your symptoms. If you have concerns about side effects or think you might be having side effects from your eczema treatment, you can reach out to your Better-Doctor affiliated doctor at any time.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse89" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>Will the steroids thin my skin or cause permanent stretch marks?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse89" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>Steroid creams may cause skin thinning or permanent stretch marks if used in large amounts, over large surface areas, and/or for a long time continuously. The general principle for using steroid creams is that you should only use as much as is needed to improve your symptoms and only for as long as is necessary.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse90" data-parent="#accordion-tab-7" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What should I do if the eczema treatment isn’t working?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse90" class="collapse" aria-expanded="false" data-parent="#accordion-tab-7" style="">
                                                    <div class="card-body">
                                                        <p>Most people experience relief of symptoms within a few days, and some even sooner. If you don’t feel that the eczema treatment is significantly improving your symptoms, reach out to your doctor or nurse practitioner anytime to talk about other options for treatment.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab8" role="tabpanel" aria-labelledby="tab8">
                                        <div class="faq-head">
                                            <h3>Dandruff</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-8">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse91" data-parent="#accordion-tab-8" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does dandruff treatment with Better-Doctor work?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse91" class="collapse" aria-expanded="false" data-parent="#accordion-tab-8" style="">
                                                    <div class="card-body">
                                                        <p>We use telemedicine technology to connect you with a U.S. licensed healthcare professional to provide dandruff treatment conveniently and affordably.</p>
                                                        <br>
                                                        <p>It starts with your online visit. Your doctor or nurse practitioner needs to know about your general health and how dandruff affects you.</p>
                                                        <p>They also need an unedited photo of you and your ID (that shows your picture and birthdate) so they know who they will be helping. A photo is also helpful to confirm the diagnosis. They review everything, determine if you’re a candidate for telemedicine and if treatment is right for you, and if so, they will send you a personalized treatment plan.</p>
                                                        <br>
                                                        <p>Their treatment plan will include information about dandruff. It is important that you take the time to read it all to be informed and better prepared to manage your condition.</p>
                                                        <p>If you qualify, your doctor or nurse practitioner will prescribe the right course of treatment to help you take control of your dandruff.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse92" data-parent="#accordion-tab-8" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What should I do if the dandruff treatment isn’t working?</span>
			                                                <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse92" class="collapse" aria-expanded="false" data-parent="#accordion-tab-8" style="">
                                                    <div class="card-body">
                                                        <p>Sometimes scalp flakes are caused by an underlying skin condition other than simple dandruff, so if you don’t think the treatment is adequately treating your symptoms, contact your Better-Doctor affiliated healthcare professional at any time to discuss your treatment plan.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab9" role="tabpanel" aria-labelledby="tab9">
                                        <div class="faq-head">
                                            <h3>HERPES</h3>
                                        </div>
                                        <div class="accordion" id="accordion-tab-9">
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse92" data-parent="#accordion-tab-9" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>How does dandruff treatment with Better-Doctor work?</span>
                                                            <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse91" class="collapse" aria-expanded="false" data-parent="#accordion-tab-9" style="">
                                                    <div class="card-body">
                                                        <p>We use telemedicine technology to connect you with a U.S. licensed healthcare professional to provide dandruff treatment conveniently and affordably.</p>
                                                        <br>
                                                        <p>It starts with your online visit. Your doctor or nurse practitioner needs to know about your general health and how dandruff affects you.</p>
                                                        <p>They also need an unedited photo of you and your ID (that shows your picture and birthdate) so they know who they will be helping. A photo is also helpful to confirm the diagnosis. They review everything, determine if you’re a candidate for telemedicine and if treatment is right for you, and if so, they will send you a personalized treatment plan.</p>
                                                        <br>
                                                        <p>Their treatment plan will include information about dandruff. It is important that you take the time to read it all to be informed and better prepared to manage your condition.</p>
                                                        <p>If you qualify, your doctor or nurse practitioner will prescribe the right course of treatment to help you take control of your dandruff.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="panel-heading">
                                                    <a class="card-header" data-toggle="collapse" href="#collapse93" data-parent="#accordion-tab-9" aria-expanded="false">
                                                        <h4 class="card-title">
                                                            <span>What should I do if the dandruff treatment isn’t working?</span>
			                                                <i class="indicator fa fa-chevron-circle-right"></i>
                                                        </h4>
                                                    </a>
                                                </div>
                                                <div id="collapse94" class="collapse" aria-expanded="false" data-parent="#accordion-tab-9" style="">
                                                    <div class="card-body">
                                                        <p>Sometimes scalp flakes are caused by an underlying skin condition other than simple dandruff, so if you don’t think the treatment is adequately treating your symptoms, contact your Better-Doctor affiliated healthcare professional at any time to discuss your treatment plan.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="heading text-center">
                            <h1>Ready to get Started?</h1>
                            <a :href='started_faq' class="mobilebutton btn start-btn common-btn hvr-push">Get Started!</a>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Config from '../../config/prod.env';

export default {
    name: "Faq",
    data() {
        return {
            started_faq: Config.STARTED_FAQ,
        }
    },  
}
</script>

<style scoped>

</style>