<template>
  <transition name="modal">
    <div :style="{ zIndex: zIndex }" class="modal-mask pt-16 md:pt-24 lg:pt-24 xl:pt-24 sm:pt-24">
      <div class="modal-wrapper">
        <div
          v-click-outside="closeModal"
          :class="{ wide: wide, 'ultra-wide': ultraWide }"
          class="modal-container rounded-lg"
          style="height: 90% !important"
        >
          <div
            v-if="!forGuide"
            class="modal-header1  text-white-normal px-2 rounded-t-lg" style="background-color: #0ca2ad"
          >
            <slot name="header"></slot>
          </div>

          <div :class="{ 'no-padd': forGuide }" class="modal-body text-center">
            <slot name="body"></slot>
          </div>

          <div v-if="hidefooter == false" class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  props: {
    forGuide: {
      type: Boolean,
      default: false
    },
    hidefooter: {
      type: Boolean,
      default: false
    },
    canvas: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    ultraWide: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 10000
    }
  },
  methods: {
    closeModal: function() {
      if (this.canvas == false) this.$emit("close");
    }
  },
  directives: {
    ClickOutside
  }
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
@media (max-width: 767px) {
  .modal-container {
    width: 99vw;
	overflow-y: scroll;
	overflow-x: hidden;
  }
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  padding: 14px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.wide {
  max-width: 700px !important;
  max-height: 98vh !important;
  overflow-y: auto;
}
.ultra-wide {
  max-width: 80vw;
}

.modal-body::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}
.modal-body::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}
</style>