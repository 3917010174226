<template>
    <div>
        <header>
            <div class="header-bottom depression-banner">
                <div class="banner-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <br>
                                    <br>
                                    <h1>Depression</h1>
                                    <br>
                                    <p>Although depression is not a normal part of aging, senior men may have medical conditions such as heart disease, stroke, cancer, or other stressors that may contribute to depression</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                    <div class="banner-features">
                                        <ul class="list-unstyled d-flex d-inline-flex m-0">
                                            <li>
                                            <span class="hvr-float-shadow">
													<img src="img/icons/labtesting-bannericon-1.webp" class="img-fluid" alt="icon" />
												</span> At-Home Testing
                                            </li>
                                            <li>
                                            <span class="hvr-float-shadow">
													<img src="img/icons/labtesting-bannericon-2.webp" class="img-fluid" alt="icon" />
												</span> In-Person Testing
                                            </li>
                                            <li>
                                            <span class="hvr-float-shadow">
													<img src="img/icons/labtesting-bannericon-3.webp" class="img-fluid" alt="icon" />
												</span> CLIA Certified Labs
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <center><a href="https://getbetterdoctor.com/forms/dist/#/depression/initial/1" class=" buttonstyle btn filled-btn labtest-btn hvr-grow">        Get Started         </a></center>
                                <br>    
                            </div>
                            <div class="col-md-3"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </header>
        <div class="holder">
            <div class="viraltesting-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>For Depression</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">TRANSPARENT PRICING</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border">
                                        <p class="price-alignment bbold">For Depression + Better Dr.</p>
                                        <p class="price-alignment bbold">30 day supply</p>
                                        <h1 class="price-alignment bbold">89$</h1>
                                        <span class="price-alignment price-sub bbold">for 12 gms + 15 gms</span>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a href="https://getbetterdoctor.com/forms/dist/#/depression/initial/1" class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <br>
                                <br>
                                <br>
                                <br>
                                <img src="img/others/depression-med-1.png" class="img-fluid" alt="post" />
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 hideDivMb">
                            <div class="viraltesting-img common-img" data-aos="fade-up" data-aos-delay="0">
                                <br>
                                <br>
                                <br>
                                <br>
                                <br>
                                <img src="img/others/depression-med-2.png" class="img-fluid" alt="post" />
                            </div>
                        </div>                           
                        <div class="col-md-6">
                            <div class="viraltesting-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>For Anxiety</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <div class="row">
                                    <div class="col">
                                        <p class="price-alignment bbold">TRANSPARENT PRICING</p>
                                    </div>
                                </div>
                                <div class="row col03">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6 price-border">
                                        <p class="price-alignment bbold">For Anxiety + Better Dr.</p>
                                        <p class="price-alignment bbold">30 day supply</p>
                                        <h1 class="price-alignment bbold">89$</h1>
                                        <span class="price-alignment price-sub bbold">for 12 gms + 15 gms</span>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="howit-btn text-center" data-aos="fade-up" data-aos-delay="0" >
                                    <a href="https://getbetterdoctor.com/forms/dist/#/depression/initial/1" class="mobilebutton btn how-btn common-btn hvr-float-shadow">Get started</a>
                                </div>
                            </div>
                        </div>                     
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";
export default {
    name: "depression",
    components: {Header},
    methods : {

        clickMethod: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function(){
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        },
        clickMethodAtPerson: function(){
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({name: 'Testing'})
        }
    }
}
</script>
<style scoped>
.bbold {
    font-weight: bold;
}
@media only screen and (min-width: 1200px) {
.price-alignment {
    display: flex;
    justify-content: center;
}
.price-sub {
    font-size: 13px;
}
.heading h1::after {
   height: 0px !important;
}
.price-border {
    border: 1px solid #0EB8B7;
    border-radius: 10px;
    padding-right: 5px ;
}
}
@media only screen and (max-width: 768px) {
.price-alignment {
    display: flex;
    justify-content: center;
}
.price-sub {
    font-size: 13px;
}
.heading h1::after {
   height: 0px !important;
}
.hideDivMb{
    display: none !important;
}
.price-border {
    border: 1px solid #0EB8B7;
    border-radius: 10px;
    padding-right: 5px ;
    margin-left: 90px;
}
}
@media only screen and (max-width: 950px) {
.price-alignment {
    display: flex;
    justify-content: center;
}
.price-sub {
    font-size: 13px;
}
.heading h1::after {
   height: 0px !important;
}
.price-border {
    border: 1px solid #0EB8B7;
    border-radius: 10px;
}
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .col03 {
    width: 80%;
  }
}

</style>